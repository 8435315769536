/* eslint-disable prettier/prettier */
import IEnhancementData from '../data/IEnhancementData';

const enhancements: IEnhancementData[] = [
    { ressource: 'eh_025', title: 'Heal', type: 'heal', trigger: 'death', time: 1000, rarity: 0, value: 5, weight: 0.1, description: '<class="tag0">Heal for </class><class="tag1">${value}%</class><class="tag0"> after kill</class>' },
    { ressource: 'eh_025', title: 'Heal', type: 'heal', trigger: 'death', time: 1000, rarity: 1, value: 10, weight: 0.1, description: '<class="tag0">Heal for </class><class="tag1">${value}%</class><class="tag0"> after kill</class>' },
    { ressource: 'eh_025', title: 'Heal', type: 'heal', trigger: 'death', time: 1000, rarity: 2, value: 20, weight: 0.1, description: '<class="tag0">Heal for </class><class="tag1">${value}%</class><class="tag0"> after kill</class>' },
    
    { ressource: 'eh_009', title: 'Shield', type: 'shield', trigger: 'death', time: 1000, rarity: 0, value: 10, weight: 0.1, description: '<class="tag0">Start each combat with </class><class="tag1">${value}%</class><class="tag0"> shield</class>' },
    { ressource: 'eh_009', title: 'Shield', type: 'shield', trigger: 'death', time: 1000, rarity: 1, value: 20, weight: 0.1, description: '<class="tag0">Start each combat with </class><class="tag1">${value}%</class><class="tag0"> shield</class>' },
    { ressource: 'eh_009', title: 'Shield', type: 'shield', trigger: 'death', time: 1000, rarity: 2, value: 30, weight: 0.1, description: '<class="tag0">Start each combat with </class><class="tag1">${value}%</class><class="tag0"> shield</class>' },
    
    { ressource: 'eh_050', title: 'Low heath', type: 'attack', trigger: 'death', time: 1000, rarity: 0, triggerValue: 25, value: 5, weight: 0.1, description: '<class="tag0">When hp is below </class><class="tag1">${triggerValue}%</class> <class="tag0">you have </class><class="tag1">${value}%</class><class="tag0"> more strength </class>' },
    { ressource: 'eh_050', title: 'Low heath', type: 'attack', trigger: 'death', time: 1000, rarity: 1, triggerValue: 25, value: 10, weight: 0.1, description: '<class="tag0">When hp is below </class><class="tag1">${triggerValue}%</class> <class="tag0">you have </class><class="tag1">${value}%</class><class="tag0"> more strength </class>' },
    { ressource: 'eh_050', title: 'Low heath', type: 'attack', trigger: 'death', time: 1000, rarity: 2, triggerValue: 25, value: 20, weight: 0.1, description: '<class="tag0">When hp is below </class><class="tag1">${triggerValue}%</class> <class="tag0">you have </class><class="tag1">${value}%</class><class="tag0"> more strength </class>' },
    
    { ressource: 'eh_942', title: 'Physical damage', type: 'damage', trigger: 'always', time: 1000, rarity: 0, value: 5, weight: 0.1, description: '<class="tag0">Increase </class><class="tag1">Physical Damage</class><class="tag0"> by </class><class="tag1">${value}%</class>' },
    { ressource: 'eh_942', title: 'Physical damage', type: 'damage', trigger: 'always', time: 1000, rarity: 1, value: 10, weight: 0.1, description: '<class="tag0">Increase </class><class="tag1">Physical Damage</class><class="tag0"> by </class><class="tag1">${value}%</class>' },
    { ressource: 'eh_942', title: 'Physical damage', type: 'damage', trigger: 'always', time: 1000, rarity: 2, value: 20, weight: 0.1, description: '<class="tag0">Increase </class><class="tag1">Physical Damage</class><class="tag0"> by </class><class="tag1">${value}%</class>' },
];

export default enhancements;
