import IEnemyData from '../data/IEnemyData';
import ISkillData from '../data/ISkillData';
import GameScene from '../scenes/GameScene';
import BaseCharacter from './BaseCharacter';
import skills from '../data/skills';

export default class Enemy extends BaseCharacter {
    public scene: GameScene;

    public enemyData: IEnemyData;

    public _nextAttackIn: number = 2;
    public turnText: Phaser.GameObjects.Text;
    private _currentSkill: ISkillData;
    private _currentDamage: number;
    public damageText: Phaser.GameObjects.Text;
    public skill: Phaser.GameObjects.Image;

    public styleRed: Phaser.Types.GameObjects.Text.TextStyle = {
        font: 'bold 25px Arial',
        fill: '#FF0000',
        stroke: '#FFFFFF',
        strokeThickness: 6,
        align: 'left',
        wordWrap: { width: 250, useAdvancedWrap: true },
    } as Phaser.Types.GameObjects.Text.TextStyle;

    public styleYellow: Phaser.Types.GameObjects.Text.TextStyle = {
        font: '25px Arial',
        fill: '#FFFF00',
        stroke: '#000000',
        strokeThickness: 6,
        align: 'left',
        wordWrap: { width: 250, useAdvancedWrap: true },
    } as Phaser.Types.GameObjects.Text.TextStyle;

    public styleWhite: Phaser.Types.GameObjects.Text.TextStyle = {
        font: '25px Arial',
        fill: '#FFFFFF',
        stroke: '#000000',
        strokeThickness: 6,
        align: 'left',
        wordWrap: { width: 250, useAdvancedWrap: true },
    } as Phaser.Types.GameObjects.Text.TextStyle;

    get nextAttackIn(): number {
        return this._nextAttackIn;
    }
    set nextAttackIn(value: number) {
        this._nextAttackIn = value;
        if (this.nextAttackIn >= 0) {
            this.turnText.setText(this.nextAttackIn.toString());
            this.turnText.setStyle(value === 0 ? this.styleRed : value === 1 ? this.styleYellow : this.styleWhite);
        }
    }

    get currentSkill(): ISkillData {
        return this._currentSkill;
    }
    set currentSkill(value: ISkillData) {
        this._currentSkill = value;
        this.skill.setTexture(value.key);
    }

    get currentDamage(): number {
        return this._currentDamage;
    }
    set currentDamage(value: number) {
        this._currentDamage = value;
        if (value) {
            this.damageText.setVisible(true);
            this.damageText.setText((value * this.multiCharacter).toString());
            this.damageText.setStyle(this.styleYellow);
        } else {
            this.damageText.setVisible(false);
        }
    }

    constructor(scene: GameScene, x: number, y: number, texture: string, data: IEnemyData, type: string) {
        super(scene, x, y, [], texture);

        const turnText = new Phaser.GameObjects.Text(scene, 0, 0, '0', {
            font: '25px Arial',
        });
        const damageText = new Phaser.GameObjects.Text(scene, 0, 0, '0', {
            font: '25px Arial',
        });
        const skill = new Phaser.GameObjects.Image(scene, 0, 0, 'skill-27');

        this.add(damageText);
        this.add(skill);
        this.add(turnText);

        this.scene = scene;
        this.turnText = turnText;
        this.damageText = damageText;
        this.enemyData = data;
        // this.nextAttackIn = data.attackEvery - 1;
        this.skill = skill;

        // this.setScale(0.5);
        this.setVisible(false);
        this.setType(type);
        this.breathe();

        this.turnText.setOrigin(0.5);
        this.turnText.setPosition(-(this.healthBar.width / 2), 0 - 5);
        this.turnText.setText(this.nextAttackIn.toString());

        this.damageText.setOrigin(1, 0.5);
        this.damageText.setPosition(-(this.healthBar.width / 2) - 30, 0 + 15);

        this.skill.setScale(0.15);
        this.skill.setPosition(-(this.healthBar.width / 2) - 20, 0);

        this.outlinePlugin.add(this.skill, {
            thickness: 3.0,
            outlineColor: 0x000000,
            quality: 1.0,
            name: 'rexOutlinePostFx',
        });

        this.currentSkill = this.getNextSkill();
        this.currentDamage = this.getNextDamage();
        this.nextAttackIn = this.currentSkill.attackIn - 1;

        this.scene.add.existing(this);
    }

    setType(type: string): void {
        if (type === 'boss') {
            this.scaleCharacter = this.SCALE * 1.2;
            this.multiCharacter = 2;
        } else {
            this.scaleCharacter = this.SCALE;
            this.multiCharacter = 1;
        }
        this.character.setScale(this.scaleCharacter);
    }

    attack(destination: BaseCharacter, multi: number): void {
        if (!this.isDead) {
            super.attack(destination, multi * this.multiCharacter, this.currentSkill.type, this.currentDamage);
            this.currentSkill = this.getNextSkill();
            this.currentDamage = this.getNextDamage();
            this.nextAttackIn = this.currentSkill.attackIn - 1;
        }
    }

    getNextSkill(): ISkillData {
        const skillName = this.enemyData.skills[Phaser.Math.Between(0, this.enemyData.skills.length - 1)];
        return skills[skillName];
    }

    getNextDamage(): number {
        return this.currentSkill.damage.length > 1 ? Phaser.Math.Between(this.currentSkill.damage[0], this.currentSkill.damage[1]) : this.currentSkill.damage[0];
    }

    die(): void {
        console.log('enemy died');
        super.die();
        this.scene.tweens.add({
            targets: [this],
            alpha: 0.1,
            yoyo: false,
            repeat: 0,
            duration: 2000,
            ease: 'Sine.easeInOut',
            onComplete: () => {
                this.setVisible(false);
                this.scene.time.delayedCall(1000, () => {
                    this.events.emit('died');
                });
            },
        });

        // TODO: trigger buffs
        this.scene.character.buffs.items.forEach((buff) => {
            if (buff.trigger === 'death') {
                if (buff.type === 'heal') {
                    this.scene.character.addHealth(Math.ceil((this.scene.character.health / 100) * buff.value));
                }
            }
        });

        // TODO: trigger tint
        // nextEnemy.character.tint = 0xffffff;
    }
}
