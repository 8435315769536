import IChainInfo from '@/data/IChainInfo';
import IBlockData from '../data/IBlockData';
import GameScene from '../scenes/GameScene';
import Block from './Block';

export default class Board {
    public scene: GameScene;
    public rows: number;
    public cols: number;
    public blockVariations: number;
    public totalBlocksCleared: number = 0;
    public grid: IBlockData[][] = new Array(0).fill(false).map(() => new Array(0).fill(false));
    public reserveGrid: number[][] = new Array(0).fill(false).map(() => new Array(0).fill(false));
    public MODULO_NUMBER: number;
    public RESERVE_ROW: number;

    constructor(scene: GameScene, rows: number, cols: number, blockVariations: number) {
        this.scene = scene;
        this.rows = rows;
        this.cols = cols;
        this.blockVariations = blockVariations;
        this.grid = [];

        for (let i = 0; i < rows; i++) {
            this.grid.push([]);

            for (let j = 0; j < cols; j++) {
                this.grid[i].push({
                    variation: 0,
                    status: 'normal',
                    timer: -1,
                });
            }
        }

        // reserve grid on the top, for when new blocks are needed
        this.reserveGrid = [];
        this.RESERVE_ROW = rows;
        this.MODULO_NUMBER = this.scene.NUM_VARIATIONS + 1;

        for (let i = 0; i < this.RESERVE_ROW; i++) {
            this.reserveGrid.push([]);

            for (let j = 0; j < cols; j++) {
                this.reserveGrid[i].push(0);
            }
        }

        this.populateGrid();
        this.populateReserveGrid();
    }

    populateGrid(): void {
        for (let i = 0; i < this.rows; i++) {
            for (let j = 0; j < this.cols; j++) {
                const variation = Phaser.Math.Between(1, this.blockVariations);
                this.grid[i][j].variation = variation;
            }
        }

        // if there are any chains, re-populate
        const nextChains = this.findAllChains(undefined);
        if (nextChains.length) {
            this.populateGrid();
        }
    }

    populateReserveGrid(): void {
        for (let i = 0; i < this.RESERVE_ROW; i++) {
            for (let j = 0; j < this.cols; j++) {
                this.reserveGrid[i][j] = Phaser.Math.Between(1, this.blockVariations);
            }
        }

        // drop chest into fist row
        if (this.totalBlocksCleared > Block.CHEST_NORMAL_EVERY) {
            this.totalBlocksCleared = 0;
            // TODO: dont overwrite other special blocks
            const row = this.RESERVE_ROW - 1;
            const col = Phaser.Math.Between(0, this.scene.NUM_COLS - 1);
            this.reserveGrid[row][col] = Block.SPECIAL_CHEST;
            this.grid[0][col].variation = 0;
            this.updateGrid();
            this.reserveGrid[row][col] = Phaser.Math.Between(1, this.blockVariations);

            const blockObject: Block | undefined = this.scene.getBlockFromColRow({ row: 0, col });
            blockObject.kill();
        }
    }

    dumpBoard(): string {
        let prettyString = '';

        for (let i = 0; i < this.RESERVE_ROW; i++) {
            prettyString += '\n';
            for (let j = 0; j < this.cols; j++) {
                prettyString += ' ' + this.reserveGrid[i][j];
            }
        }

        prettyString += '\n';

        for (let j = 0; j < this.cols; j++) {
            prettyString += ' -';
        }

        for (let i = 0; i < this.rows; i++) {
            prettyString += '\n';
            for (let j = 0; j < this.cols; j++) {
                prettyString += ' ' + this.grid[i][j].variation + (this.grid[i][j].status === 'normal' ? '' : '*');
            }
        }
        return prettyString;
    }

    // swapping blocks
    swap(source, target): void {
        const temp = this.grid[target.row][target.col];
        this.grid[target.row][target.col] = {
            variation: this.grid[source.row][source.col].variation,
            status: this.grid[source.row][source.col].status,
            timer: this.grid[source.row][source.col].timer,
        };
        this.grid[source.row][source.col] = {
            variation: temp.variation,
            status: temp.status,
            timer: temp.timer,
        };

        const tempPos = { row: source.row, col: source.col };
        source.row = target.row;
        source.col = target.col;

        target.row = tempPos.row;
        target.col = tempPos.col;
    }

    // check if two blocks are adjacent
    checkAdjacent(source, target): boolean {
        const diffRow = Math.abs(source.row - target.row);
        const diffCol = Math.abs(source.col - target.col);
        return (diffRow === 1 && diffCol === 0) || (diffRow === 0 && diffCol === 1);
    }

    isMatchingVariation(row: number, col: number, variation: number): boolean {
        return this.grid[row] && this.grid[row][col] && this.grid[row][col].variation < 100 && variation < 100 && variation % this.MODULO_NUMBER === this.grid[row][col].variation % this.MODULO_NUMBER;
    }

    // check whether a single block is chained or not
    getChainInfo(block): { isChained: boolean; group: { row: number; col: number }[] } {
        // console.log('getChainInfo', block);
        let isChained = false;
        const row = block.row;
        const col = block.col;
        const group: { row: number; col: number }[] = [];

        if (block.row === null || block.col === null) {
            return {
                isChained,
                group,
            };
        }

        const variation = this.grid[block.row][block.col].variation;

        // left
        if (this.isMatchingVariation(row, col - 1, variation) && this.isMatchingVariation(row, col - 2, variation)) {
            group.push({ row: row, col: col });
            group.push({ row: row, col: col - 1 });
            group.push({ row: row, col: col - 2 });
            if (this.isMatchingVariation(row, col + 1, variation)) {
                group.push({ row: row, col: col + 1 });
                if (this.isMatchingVariation(row, col + 2, variation)) {
                    group.push({ row: row, col: col + 2 });
                }
            }
            return {
                isChained: true,
                group,
            };
        }

        // right
        if (this.isMatchingVariation(row, col + 1, variation) && this.isMatchingVariation(row, col + 2, variation)) {
            group.push({ row: row, col: col });
            group.push({ row: row, col: col + 1 });
            group.push({ row: row, col: col + 2 });
            if (this.isMatchingVariation(row, col - 1, variation)) {
                group.push({ row: row, col: col - 1 });
                if (this.isMatchingVariation(row, col - 2, variation)) {
                    group.push({ row: row, col: col - 2 });
                }
            }
            return {
                isChained: true,
                group,
            };
        }

        // up
        // if (this.grid[row - 2]) {
        if (this.isMatchingVariation(row - 1, col, variation) && this.isMatchingVariation(row - 2, col, variation)) {
            isChained = true;
            group.push({ row: row, col: col });
            group.push({ row: row - 1, col: col });
            group.push({ row: row - 2, col: col });
            if (this.isMatchingVariation(row + 1, col, variation)) {
                group.push({ row: row + 1, col: col });
                if (this.isMatchingVariation(row + 2, col, variation)) {
                    group.push({ row: row + 2, col: col });
                }
            }
            return {
                isChained: true,
                group,
            };
        }
        // }

        // down
        // if (this.grid[row + 2]) {
        if (this.isMatchingVariation(row + 1, col, variation) && this.isMatchingVariation(row + 2, col, variation)) {
            isChained = true;
            group.push({ row: row, col: col });
            group.push({ row: row + 1, col: col });
            group.push({ row: row + 2, col: col });
            if (this.isMatchingVariation(row - 1, col, variation)) {
                group.push({ row: row - 1, col: col });
                if (this.isMatchingVariation(row - 2, col, variation)) {
                    group.push({ row: row - 2, col: col });
                }
            }
            return {
                isChained: true,
                group,
            };
        }
        // }

        // center - horizontal
        /* if (this.isMatchingVariation(row, col - 1, variation) && this.isMatchingVariation(row, col + 1, variation)) {
            group.push({ row: row, col: col + 1 });
            group.push({ row: row, col: col });
            group.push({ row: row, col: col - 1 });
            if (this.isMatchingVariation(row, col - 2, variation)) {
                group.push({ row: row, col: col - 2 });
            }
            if (this.isMatchingVariation(row, col + 2, variation)) {
                group.push({ row: row, col: col + 2 });
            }
            return {
                isChained: true,
                group,
            };
        }

        // center - vertical
        if (this.grid[row + 1] && this.grid[row - 1]) {
            if (this.isMatchingVariation(row - 1, col, variation) && this.isMatchingVariation(row + 1, col, variation)) {
                isChained = true;
                group.push({ row: row - 1, col: col });
                group.push({ row: row, col: col });
                group.push({ row: row + 1, col: col });
                if (this.isMatchingVariation(row, col + 2, variation)) {
                    group.push({ row: row, col: col + 2 });
                }
                if (this.isMatchingVariation(row, col - 2, variation)) {
                    group.push({ row: row, col: col - 2 });
                }
                return {
                    isChained: true,
                    group,
                };
            }
        } */

        return {
            isChained,
            group,
        };
    }

    isAlreadyChained(items: IChainInfo[], wantsIn: { isChained: boolean; group: { row: number; col: number }[] }): boolean {
        const isAlready = ({ a, b }: { a: { row: number; col: number }[]; b: { row: number; col: number }[] }): boolean => {
            let result = true;
            for (const element of a) {
                if (!b.some((otherElement) => element.row === otherElement.row && element.col === otherElement.col)) {
                    result = false;
                    break;
                }
            }
            return result;
        };
        for (const item of items) {
            if (isAlready({ a: item.group, b: wantsIn.group }) || isAlready({ a: wantsIn.group, b: item.group })) {
                return true;
            }
        }
        return false;
    }

    // find all the chains
    findAllChains(block1: Block /* , block2 */): IChainInfo[] {
        const chained: IChainInfo[] = [];
        let i: number, j: number;

        // check for swapped first
        if (block1) {
            const chainInfo = this.getChainInfo({
                row: block1.row,
                col: block1.col,
            });
            if (chainInfo.isChained && !this.isAlreadyChained(chained, chainInfo)) {
                chained.push({
                    row: block1.row,
                    col: block1.col,
                    group: chainInfo.group,
                    items: [],
                });
                // return chained;
            }
        }

        for (i = 0; i < this.rows; i++) {
            for (j = 0; j < this.cols; j++) {
                const chainInfo = this.getChainInfo({ row: i, col: j });
                if (chainInfo.isChained && !this.isAlreadyChained(chained, chainInfo)) {
                    chained.push({
                        row: i,
                        col: j,
                        group: chainInfo.group,
                        items: [],
                    });
                }

                // check if block is chest or bomb
                if (i === this.rows - 1) {
                    if (this.grid[i] && this.grid[i][j].variation > 100) {
                        chained.push({
                            row: i,
                            col: j,
                            group: [{ row: i, col: j }],
                            items: [],
                        });
                    }
                }
            }
        }
        return chained; // .reduce((previous, chain) => (!previous || chain.group.length > previous.group.length ? chain : previous), undefined);
    }

    getSurroundingBlocks(block: { row: number; col: number }, radius: number, chance: number): { row: number; col: number }[] {
        const blocks: { row: number; col: number }[] = [];
        for (let i = block.row - radius; i <= block.row + radius; i++) {
            for (let j = block.col - radius; j <= block.col + radius; j++) {
                if (this.grid[i] && this.grid[i][j] && Phaser.Math.Between(0, 100) < chance) {
                    blocks.push({
                        row: i,
                        col: j,
                    });
                }
            }
        }
        return blocks;
    }

    // clear all the chains
    getNextChains(block1: Block /* , block2: Block | undefined */): IChainInfo[] {
        //gets all blocks that need to be cleared
        const nextBlocks = this.findAllChains(block1 /* , block2 */);
        // console.log('nextBlocks', nextBlocks);
        nextBlocks.forEach((nextBlock) => {
            // const nextBlock = nextChain[0];
            let variation = 0;

            nextBlock.items = [];

            for (const block of nextBlock.group) {
                variation = this.grid[block.row][block.col].variation;

                this.totalBlocksCleared += 1;
                // console.log('this.totalBlocksCleared', this.totalBlocksCleared)
                if (nextBlock.group.length === 1) {
                    if (variation > 100) {
                        const group = nextBlock.group[0];
                        this.grid[group.row][group.col].variation = 0;
                        const blockObject: Block | undefined = this.scene.getBlockFromColRow(group);
                        if (blockObject) {
                            nextBlock.items.push({
                                texture: blockObject.texture.key,
                                x: blockObject.x,
                                y: blockObject.y,
                            });
                            blockObject.kill();
                        }
                    }
                } else if (nextBlock.group.length > 3 && block.row === block1.row && block.col === block1.col) {
                    // console.log('ding', variation, block);
                    const blockObject: Block | undefined = this.scene.getBlockFromColRow(block);
                    if (blockObject) {
                        nextBlock.items.push({
                            texture: blockObject.texture.key,
                            x: blockObject.x,
                            y: blockObject.y,
                        });
                        blockObject.deselect();
                    }
                } else {
                    // console.log('dong', variation, block);
                    this.grid[block.row][block.col].variation = 0;
                    const blockObject: Block | undefined = this.scene.getBlockFromColRow(block);
                    if (blockObject) {
                        nextBlock.items.push({
                            texture: blockObject.texture.key,
                            x: blockObject.x,
                            y: blockObject.y,
                        });
                        blockObject.deselect();
                        blockObject.kill();
                    }
                }
            }

            // we have 4 stones matched
            if (block1 && block1.row && block1.col && nextBlock.group.length === 4) {
                this.grid[block1.row][block1.col].variation = variation + this.MODULO_NUMBER;
                console.log('var4', this.grid[block1.row][block1.col].variation, block1)
                const blockObject = this.scene.getBlockFromColRow(block1);
                if (blockObject) {
                    console.log('resetTexture')
                    blockObject.resetTexture(this.grid[block1.row][block1.col].variation, 1);
                }
            }

            // we have 5 stones matched
            if (block1 && block1.row && block1.col && nextBlock.group.length === 5) {
                this.grid[block1.row][block1.col].variation = variation + this.MODULO_NUMBER + this.MODULO_NUMBER;
                console.log('var5', this.grid[block1.row][block1.col].variation)
                const blockObject = this.scene.getBlockFromColRow(block1);
                if (blockObject) {
                    blockObject.resetTexture(this.grid[block1.row][block1.col].variation, 2);
                }
            }
            // return nextBlock;
        });
        return nextBlocks;
    }

    getRarity(texture: string): number {
        return texture === 'block6' || texture === 'block7' || texture === 'block8' || texture === 'block9' ? 1 : texture === 'block11' || texture === 'block12' || texture === 'block13' || texture === 'block14' ? 2 : 0;
    }

    // drop a block in the main grid from a position to another. the source is set to zero
    dropBlock(sourceRow, targetRow, col): void {
        this.grid[targetRow][col] = {
            variation: this.grid[sourceRow][col].variation,
            status: this.grid[sourceRow][col].status,
            timer: this.grid[sourceRow][col].timer,
        };
        this.grid[sourceRow][col].variation = 0;

        this.scene.dropBlock(sourceRow, targetRow, col);
    }

    // drop a block in the reserve grid from a position to another. the source is set to zero
    dropReserveBlock(sourceRow, targetRow, col): void {
        this.grid[targetRow][col] = {
            variation: this.reserveGrid[sourceRow][col],
            status: 'normal',
            timer: this.reserveGrid[sourceRow][col] === Block.SPECIAL_CHEST ? 6 : this.reserveGrid[sourceRow][col] >= 200 ? 5 : -1,
        };
        this.reserveGrid[sourceRow][col] = 0;

        this.scene.dropReserveBlock(sourceRow, targetRow, col);
    }

    // move down blocks to fill in empty slots
    updateGrid(): void {
        let foundBlock;

        //go through all the rows, from the bottom up
        for (let i = this.rows - 1; i >= 0; i--) {
            for (let j = 0; j < this.cols; j++) {
                //if the block if zero, then get climb up to get a non-zero one
                if (this.grid[i][j].variation === 0) {
                    foundBlock = false;

                    //climb up in the main grid
                    for (let k = i - 1; k >= 0; k--) {
                        if (this.grid[k][j].variation > 0) {
                            this.dropBlock(k, i, j);
                            foundBlock = true;
                            break;
                        }
                    }

                    if (!foundBlock) {
                        //climb up in the reserve grid
                        for (let k = this.RESERVE_ROW - 1; k >= 0; k--) {
                            if (this.reserveGrid[k][j] > 0) {
                                this.dropReserveBlock(k, i, j);
                                break;
                            }
                        }
                    }
                }
            }
        }

        // repopulate the reserve
        this.populateReserveGrid();
    }
}
