import App from '@/app';
import logger from "@/logger";
import * as Assets from '@/assets';
import * as PixelUI from '@/pixelui';
import BgmPlayer from '@/utils/BgmPlayer';
import HandlerScene from './HandlerScene';
import DataManager from '@/utils/DataManager';

export default class TitleScene extends Phaser.Scene {
    // private tapToStartText: Phaser.GameObjects.GameObject = null;
    private debugInfo: PixelUI.DebugInfo;
    // private sfxAudioSprites: Phaser.Sound.WebAudioSound | Phaser.Sound.HTML5AudioSound = null;
    public handlerScene: HandlerScene;
    public width: number;
    public height: number;
    public dataManager: DataManager;

    public init(): void {
        //
    }

    public preload(): void {
        // this.sfxAudioSprites = this.sound.addAudioSprite(Assets.Audiosprites.AudiospritesSound.getName());

        // BgmPlayer.instance.play([Assets.Audio.AudioBgm.getMP3(), Assets.Audio.AudioBgm.getOGG()]);

        this.width = App.SIZE_WIDTH_SCREEN;
        this.height = App.SIZE_HEIGHT_SCREEN;
        this.handlerScene = this.scene.get('HandlerScene') as HandlerScene;
        this.dataManager = new DataManager();
    }

    public create(): void {
        this.handlerScene.updateResize(this);

        /* const spaceKey = this.input.keyboard.addKey('SPACE');
        spaceKey.on('down', () => {
            this.startGame();
        });
        this.input.on('pointerdown', () => {
            this.startGame();
        }); */

        this.debugInfo = PixelUI.add.debugInfo(this);

        PixelUI.add.textLabel(this, 0, 0, ['', 'Match Knight', 'v0.1', ''], {
            textSize: 'large',
            color: PixelUI.theme.styles.colorLightAccent,
            fixedWidth: this.width,
            align: 'right',
            padding: { x: 20, y: 20 },
        });

        /* this.tapToStartText = PixelUI.add.textLabel(this, 0, GAME_HEIGHT - 80, 'Tap to Start', {
            textSize: 'small',
            fixedWidth: this.width,
            align: 'center',
        });

        this.tweens.add({
            targets: [this.tapToStartText],
            alpha: { from: 0, to: 1 },
            ease: 'CubicOut',
            duration: 800,
            repeat: -1,
            yoyo: true,
        }); */

        PixelUI.add.button(this, this.width / 2, 350, 'Play', async () => await this.showPlayDialog());
        PixelUI.add.button(this, this.width / 2, 450, 'Upgrades' + (this.dataManager.gold > 0 ? ' (' + this.dataManager.gold + ')' : ''), async () => await this.showLootboxDialog());
        PixelUI.add.button(this, this.width / 2, 550, 'Settings', async () => await this.showToast());
        PixelUI.add.button(this, this.width / 2, 650, '👑 Premium', async () => await this.showDialogPremium());
    }

    public update(): void {
        this.debugInfo.update();
    }

    private async showPlayDialog(): Promise<void> {
        // this.sfxAudioSprites.play('se_button_over');
        const dialog = PixelUI.add.dialog(this, 'Match Knight', ['Welcome to Match Knight', '', 'Kill all monsters', 'collect coins', 'Upgrade', 'Beat the boss'], {
            buttons: [
                { text: 'Ok', value: 'ok' },
                { text: 'Cancel', value: 'cancel' },
            ],
            onSelect: (value) => {
                // this.sfxAudioSprites.play('se_button_over');
                console.log(value);
                if (value === 'ok') {
                    this.startGame();
                }
            },
        });
        await dialog.open();
    }

    private async showLootboxDialog(): Promise<void> {
        const dialog = PixelUI.add.lootboxDialog(this, {
            buttons: [
                { text: 'Open (25)', value: 'open' },
                { text: 'Cancel', value: 'cancel' },
            ],
            onSelect: (value: string) => {
                console.log('value', value);
                if (value === 'open') {
                    dialog.openChest();
                } else {
                    dialog.close();
                }
            },
        });
        await dialog.open();
        /* this.enhancementDialog.events.on('click', (data: IEnhancementData) => {
            this.character.buffs.add({
                ressource: data.ressource,
                type: data.type,
                time: data.time,
                trigger: data.trigger,
                value: data.value,
            });
        }); */
    }

    private async showDialogPremium(): Promise<void> {
        // this.sfxAudioSprites.play('se_button_over');
        const dialog = PixelUI.add.dialog(this, 'Premium', ['- No ads', '- Earn 2x coins', '- Additional characters'], {
            buttons: [
                { text: 'Buy $1 / week', value: 'ok' },
                { text: 'Cancel', value: 'cancel' },
            ],
            onSelect: (value) => {
                // this.sfxAudioSprites.play('se_button_over');
                console.log(value);
                // this.startGame();
                if (value === 'cancel') {
                    dialog.close();
                }
            },
        });
        await dialog.open();
    }

    private async showDialogSmall(): Promise<void> {
        // this.sfxAudioSprites.play('se_button_over');
        const dialog = PixelUI.add.dialog(this, null, 'Are you Ok?', {
            buttons: [{ text: 'Ok', value: 'ok' }],
            backdropClose: true,
            onSelect: () => {
                // this.sfxAudioSprites.play('se_button_over');
            },
        });
        await dialog.open();
    }

    index = 0;
    private async showToast(): Promise<void> {
        if (this.index % 3 === 0) {
            PixelUI.add.toast(this, undefined, `Hi! this is toast message! (${this.index})`, { verticalAlign: 'top' });
        }
        if (this.index % 3 === 1) {
            PixelUI.add.toast(this, 'Success', `Hi! this is toast success! (${this.index})`, { verticalAlign: 'top', type: 'success' });
        }
        if (this.index % 3 === 2) {
            PixelUI.add.toast(this, 'Error', `Hi! this is toast danger! (${this.index})`, { verticalAlign: 'top', type: 'error' });
        }
        this.index += 1;
        return Promise.resolve();
    }

    private async showToggleState(toggle: boolean): Promise<void> {
        PixelUI.add.toast(this, undefined, `Toggled! (${toggle})`, {
            verticalAlign: 'top',
        });
        return Promise.resolve();
    }

    private startGame(): void {
        console.log('start game');
        /* if (this.tapToStartText) {
            this.tapToStartText.destroy();
            this.tapToStartText = null;
        } */
        this.scene.stop('TitleScene');
        this.scene.start('GameScene');
        logger.info('Start game3');
        logger.log('Start game4');
    }
}
