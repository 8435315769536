export default class DataManager {
    public cheats: boolean;
    public gold: number;

    constructor() {
        this.gold = parseInt(localStorage.getItem('gold'), 10) || 0;
        this.cheats = localStorage.getItem('cheats') === '1' || false;
    }

    saveGold(gold: number): void {
        localStorage.setItem('gold', gold.toString());
    }
}
