import GameScene from '../scenes/GameScene';

export default class Block extends Phaser.GameObjects.Container {
    public scene: GameScene;
    public particles: Phaser.GameObjects.Particles.ParticleEmitterManager;
    public particlesEmitter: Phaser.GameObjects.Particles.ParticleEmitter;
    public icon: Phaser.GameObjects.Sprite;
    public animation: Phaser.GameObjects.Sprite;
    public countdownText: Phaser.GameObjects.Text;
    public multiText: Phaser.GameObjects.Text;
    public arrowGuide: Phaser.GameObjects.Graphics;
    public variation: number;
    public row: number | null;
    public col: number | null;
    public rarity: number = 0;

    public static SPECIAL_CHEST: number = 101;
    public static SPECIAL_BOMB: number = 200;
    public static SPECIAL_MUSHROOM: number = 210;
    public static CHEST_NORMAL_EVERY: number = 100;

    get texture(): Phaser.Textures.Texture {
        return this.icon.texture;
    }
    set texture(value: Phaser.Textures.Texture) {
        this.icon.texture = value;
    }

    constructor(scene: GameScene, x: number, y: number, data: { row: number; col: number; variation: number; timer: number }) {
        super(scene, x, y, []);

        const countdownText = new Phaser.GameObjects.Text(scene, 0, 0, data.timer?.toString(), {
            font: 'bold 30px Arial',
            fill: '#111',
            stroke: '#FFFFFF',
            strokeThickness: 6,
        } as Phaser.Types.GameObjects.Text.TextStyle);

        const multiText = new Phaser.GameObjects.Text(scene, 0, 0, '2x', {
            font: 'bold 30px Arial',
            fill: '#111',
            stroke: '#FFFFFF',
            strokeThickness: 6,
        } as Phaser.Types.GameObjects.Text.TextStyle);

        const icon = new Phaser.GameObjects.Sprite(scene, 0, 0, 'block' + data.variation);
        const arrowGuide = new Phaser.GameObjects.Graphics(scene);

        this.row = data.row;
        this.col = data.col;
        this.icon = icon;
        this.arrowGuide = arrowGuide;
        this.countdownText = countdownText;
        this.multiText = multiText;
        this.variation = data.variation;
        this.animation = this.scene.add.sprite(0, 0, 'fire-block');

        this.particles = this.scene.add.particles('flares');

        // this.arrowGuide.setDepth(1);
        this.icon.setScale(this.scene.BLOCK_SCALE);
        // this.icon.setDepth(2);
        this.countdownText.setOrigin(1, 0);
        this.countdownText.setVisible(data.timer > -1 && data.timer < 6);
        this.multiText.setOrigin(1, 0);
        this.multiText.setVisible(false);

        this.setSize(this.icon.width * this.scene.BLOCK_SCALE, this.icon.height * this.scene.BLOCK_SCALE);
        this.setInteractive({ dropZone: true });
        //// this.setDepth(100);

        this.add(this.arrowGuide);
        this.add(this.particles);
        this.add(this.icon);
        this.add(this.countdownText);
        this.add(this.multiText);
        this.add(this.animation);

        this.animation.setOrigin(0.5, 0.5);
        this.animation.setVisible(false);
        // this.animation.anims.play('fire-block', true);
        // this.scene.add.existing(this.animation); // fix 3.55.2 bug

        this.on('pointerdown', () => {
            this.scene.pickBlock(this);
        });
    }

    reset(x: number, y: number, data: { row: number; col: number; variation: number; timer: number }): void {
        this.setPosition(x, y);
        this.icon.setTexture('block' + data.variation);
        this.row = data.row;
        this.col = data.col;
        this.variation = data.variation;
        this.countdownText.setText(data.timer?.toString());
        this.countdownText.setVisible(data.timer > -1 && data.timer < 6);
        this.particles.removeEmitter(this.particlesEmitter);

        this.bringToTop(this.particles);
        this.bringToTop(this.icon);
        this.bringToTop(this.countdownText);
    }

    changeStatus(texture: string): void {
        if (texture === 'poison') {
            this.animation.setScale(this.scene.BLOCK_SCALE * 2.0);
            this.animation.setAlpha(0.65);
        } else {
            this.animation.setScale(this.scene.BLOCK_SCALE * 1.3);
            this.animation.setAlpha(0.75);
        }
        this.animation.setVisible(true);
        this.bringToTop(this.animation);
        this.animation.setTexture(texture);
        this.scene.time.delayedCall(Phaser.Math.Between(50, 200), () => {
            this.animation.anims.play(texture, true);
        });
    }

    resetTexture(variation: number, rarity: number): void {
        const circle = new Phaser.Geom.Circle(0, 0, 120 * this.scene.BLOCK_SCALE);

        this.icon.setTexture('block' + variation);
        this.rarity = rarity;
        this.multiText.setVisible(rarity > 0);
        this.multiText.setText((rarity == 1 ? 2 : 3) + 'x');
        this.bringToTop(this.multiText);

        /* this.particlesEmitter = this.particles.createEmitter({
            frame: rarity === 1 ? 'yellow' : 'red',
            x: 0,
            y: 0,
            lifespan: 500,
            quantity: 1,
            scale: 1.0 * this.scene.BLOCK_SCALE,
            alpha: { start: 1, end: 0 },
            blendMode: 'ADD',
            emitZone: {
                type: 'random',
                source: {
                    getRandomPoint: (vec): Phaser.Types.Math.Vector2Like => {
                        const t = Phaser.Math.PI2 * Math.random();
                        const r = Math.pow(Math.random(), -0.1);
                        vec.x = circle.x + r * Math.cos(t) * circle.radius;
                        vec.y = circle.y + r * Math.sin(t) * circle.radius;
                        return vec;
                    },
                },
            },
        }); */
    }

    lightFuse(): void {
        const circle = new Phaser.Geom.Circle(-(this.width / 2.5), -(this.height / 2.2), 10);
        this.bringToTop(this.icon);
        this.bringToTop(this.countdownText);
        this.bringToTop(this.particles);
        this.particlesEmitter = this.particles.createEmitter({
            frame: 'yellow',
            x: 0,
            y: 0,
            lifespan: 100,
            quantity: 1,
            scale: 1.0 * this.scene.BLOCK_SCALE,
            alpha: { start: 1, end: 0 },
            blendMode: 'ADD',
            emitZone: {
                type: 'random',
                source: {
                    getRandomPoint: (vec): Phaser.Types.Math.Vector2Like => {
                        const t = Phaser.Math.PI2 * Math.random();
                        const r = Math.pow(Math.random(), -0.1);
                        vec.x = circle.x + r * Math.cos(t) * circle.radius;
                        vec.y = circle.y + r * Math.sin(t) * circle.radius;
                        return vec;
                    },
                },
            },
        });
    }

    drawArrow(): void {
        this.drawArrowY(0, 0, 0, (8 - this.row) * this.scene.BLOCK_SIZE + this.scene.BLOCK_SIZE / 1.25, 20, 0xff0000);
    }

    drawArrowY(fromx: number, fromy: number, tox: number, toy: number, arrowWidth: number, color: number): void {
        const angle = Math.atan2(toy - fromy, tox - fromx);
        const hyp = Math.sqrt((tox - fromx) * (tox - fromx) + (toy - fromy) * (toy - fromy));
        const arrowAlpha = 0.3;

        this.arrowGuide.clear();
        // this.arrowGuide.save();

        this.arrowGuide.translateCanvas(fromx, fromy);
        this.arrowGuide.rotateCanvas(angle);

        this.arrowGuide.beginPath();
        this.arrowGuide.lineStyle(arrowWidth, color, arrowAlpha);
        this.arrowGuide.moveTo(0, 0);
        this.arrowGuide.lineTo(hyp - arrowWidth / 2, 0);
        this.arrowGuide.stroke();

        this.arrowGuide.beginPath();
        this.arrowGuide.lineStyle(arrowWidth / 2, color, arrowAlpha);
        this.arrowGuide.lineTo(hyp - arrowWidth, arrowWidth);
        this.arrowGuide.lineTo(hyp, 0);
        this.arrowGuide.lineTo(hyp - arrowWidth, -arrowWidth);
        this.arrowGuide.stroke();

        // this.arrowGuide.restore();
    }

    select(): void {
        this.icon.tint = 0x555555;
    }

    deselect(): void {
        this.icon.tint = 0xffffff;
    }

    setTimer(value: number): void {
        if (value > -1) {
            this.countdownText.setVisible(true);
            this.countdownText.setText(value.toString());
        } else if (value > 5) {
            this.countdownText.setVisible(false);
        }
    }

    hasMoved(): void {
        if (this.variation > 100) {
            this.drawArrow();
        }
    }

    kill(): void {
        this.col = null;
        this.row = null;
        this.animation.setVisible(false);
        this.icon.setTintFill(0xffffff);
        this.multiText.setVisible(false);
        if (this.particlesEmitter && this.particlesEmitter.active) {
            this.particlesEmitter.stop();
        }
        this.scene.time.delayedCall(100, () => {
            this.icon.clearTint();
            this.arrowGuide.clear();
            this.setActive(false);
            this.setVisible(false);
        });
    }
}
