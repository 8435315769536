import ILevelData from '../data/ILevelData';

const levels: ILevelData[] = [
    {
        background: 'bg20',
        encounters: [
            {
                type: 'normal',
                enemies: [{ types: ['or06', 'or07'] }, { types: ['or06', 'or07'] }],
            },
            {
                type: 'normal',
                enemies: [{ types: ['or01', 'or02', 'or03'] }, { types: ['or01', 'or02', 'or03'] }],
            },
            {
                type: 'normal',
                enemies: [{ types: ['or04', 'or05', 'or06'] }],
            },
            {
                type: 'boss',
                enemies: [{ types: ['or10'] }],
            },
        ],
    },
    {
        background: 'bg16',
        encounters: [
            {
                type: 'normal',
                enemies: [{ types: ['un01', 'un02', 'un03'] }, { types: ['un01', 'un02', 'un03'] }],
            },
            {
                type: 'normal',
                enemies: [{ types: ['un01', 'un02', 'un03'] }, { types: ['un01', 'un02', 'un03'] }],
            },
            {
                type: 'normal',
                enemies: [{ types: ['un01', 'un02', 'un03'] }, { types: ['un01', 'un02', 'un03'] }],
            },
            {
                type: 'normal',
                enemies: [{ types: ['un01', 'un02', 'un03'] }, { types: ['un01', 'un02', 'un03'] }],
            },
            {
                type: 'boss',
                enemies: [{ types: ['un10'] }],
            },
        ],
    },
];

export default levels;
