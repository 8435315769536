import * as PixelUI from '@/pixelui';

import Board from '../objects/Board';
import Block from '../objects/Block';
import Character from '../objects/Character';
import Enemy from '../objects/Enemy';
import OrderManager from '../objects/OrderManager';
import levels from '../data/Levels';
import ILevelData from '../data/ILevelData';
import IEncounterData from '../data/IEncounterData';
import IEnemyTypeData from '../data/IEnemyTypeData';
// import Handler from './handler';
import enemies from '../data/Enemies';
import HandlerScene from './HandlerScene';
import App from '@/app';
import FloatingNumbersPlugin from '@/utils/FloatingNumbersPlugin';
import IEnhancementData from '@/data/IEnhancementData';
import DataManager from '@/utils/DataManager';
import ShakePositionPlugin from 'phaser3-rex-plugins/plugins/shakeposition-plugin';

export default class GameScene extends Phaser.Scene {
    private debugInfo: PixelUI.DebugInfo;

    public NUM_ROWS: number = 8;
    public NUM_COLS: number = 8;
    public PADDING_TOP: number;
    public NUM_VARIATIONS: number = 4;
    public BORDER_WIDTH: number;

    public BLOCK_SIZE: number;
    public ANIMATION_TIME: number;
    public BLOCK_SCALE: number;

    public shaker: any;
    public sceneStopped: boolean;
    public width: number;
    public height: number;
    // public handlerScene: Handler;

    public background: Phaser.GameObjects.Sprite;
    public background1: Phaser.GameObjects.Graphics;
    public background11: Phaser.GameObjects.Graphics;
    public background2: Phaser.GameObjects.Sprite;
    public backgroundTint: Phaser.GameObjects.Rectangle;
    public board: Board;
    public blocks: Phaser.GameObjects.Group;
    public graphics: Phaser.GameObjects.Graphics;

    public floatingNumbers: FloatingNumbersPlugin;

    public isReversingSwap: boolean;
    public isBoardBlocked: boolean;
    public selectedBlock: Block | undefined;
    public targetBlock: Block | undefined;

    public character: Character;
    public enemy: Enemy | undefined;
    public enemies: Enemy[];
    public levels: any[];
    public levelCurrent: number = 0;
    public encounterCurrent: number = 0;

    public enhancementDialog: PixelUI.EnhancementDialog;
    public orderManager: OrderManager;
    public dataManager: DataManager;
    public levelObjects: any[] = [];
    public shakePosition: ShakePositionPlugin;

    public handlerScene: HandlerScene;

    public init(): void {
        //
    }

    public preload(): void {
        //
        this.width = App.SIZE_WIDTH_SCREEN;
        this.height = App.SIZE_HEIGHT_SCREEN;
        this.handlerScene = this.scene.get('HandlerScene') as HandlerScene;
    }

    public create(): void {
        /* this.cameras.main.flash(400, 0, 0, 0);
        const shaderWidth = this.cameras.main.width;
        const shaderHeight = this.cameras.main.height;
        this.add.shader(
            Assets.Shaders.ShadersShader.getName(),
            this.cameras.main.centerX,
            this.cameras.main.centerY,
            shaderWidth,
            shaderHeight
        ); */
        this.handlerScene.updateResize(this);

        this.debugInfo = PixelUI.add.debugInfo(this);
        // console.log('this.cameras.main.width', this.cameras.main.width, this.cameras.main.height);

        /* this.scene.launch('enhancements', {
            mainScene: this,
            width: this.width,
            height: this.height,
        }); */

        this.orderManager = new OrderManager(this);
        this.dataManager = new DataManager();

        this.BORDER_WIDTH = this.width / 10;
        this.BLOCK_SIZE = (this.width - this.BORDER_WIDTH * 2) / this.NUM_ROWS;
        this.PADDING_TOP = this.height - this.BLOCK_SIZE * this.NUM_ROWS - this.BORDER_WIDTH;
        console.log('this.BLOCK_SIZE', this.width, this.BLOCK_SIZE, this.BORDER_WIDTH, this.PADDING_TOP);
        this.ANIMATION_TIME = 600;
        this.BLOCK_SCALE = 1 / (256 / this.BLOCK_SIZE);
        console.log('this.BLOCK_SCALE', this.BLOCK_SCALE);

        this.backgroundTint = this.add.rectangle(0, 0, this.width * 2, this.height, 0xffffff, 0.1);

        this.background2 = this.add.sprite(0, 690, 'bg23');
        this.background2.setOrigin(0, 0.5);
        this.background2.setScale(1 / (2048 / this.width));
        // this.background2.setAlpha(0.7);

        // border between board and background
        this.background1 = this.add.graphics();
        this.background1.fillGradientStyle(0x000000, 0x000000, 0x000000, 0x000000, 1, 1, 0, 0);
        this.background1.fillRect(0, this.PADDING_TOP - 55, this.width, 25);
        this.background1.fillGradientStyle(0x000000, 0x000000, 0x000000, 0x000000, 0, 0, 1, 1);
        this.background1.fillRect(0, this.PADDING_TOP - 80, this.width, 25);

        this.board = new Board(this, this.NUM_ROWS, this.NUM_COLS, this.NUM_VARIATIONS);
        this.blocks = this.add.group();
        this.graphics = this.make.graphics({});
        this.drawBoard();

        this.input.dragDistanceThreshold = 16;

        this.input.on('drop', (pointer, gameObject, dropZone) => {
            if (!this.isBoardBlocked && this.board.checkAdjacent(gameObject, dropZone)) {
                if (gameObject.variation < 100 && dropZone.variation < 100) {
                    this.swapBlocks(gameObject, dropZone);
                }
            }
        });

        this.character = new Character(this, 0, 0);
        this.character.setPositionXY(100, this.PADDING_TOP + this.BORDER_WIDTH - this.character.height / 2);

        this.shakePosition = this.plugins.get('rexShakePosition') as ShakePositionPlugin;
        this.shaker = this.shakePosition.add(this.background2, {
            duration: 200,
            magnitude: 5,
            mode: 'effect',
        });

        this.levels = levels.map((level: ILevelData) => {
            return {
                background: level.background,
                encounters: level.encounters.map((encounter: IEncounterData) => {
                    return {
                        type: encounter.type,
                        enemies: encounter.enemies.map((enemy: IEnemyTypeData) => {
                            const random: number = Phaser.Math.Between(0, enemy.types.length - 1);
                            return new Enemy(this, 0, 0, enemy.types[random], enemies[enemy.types[random]], encounter.type);
                        }),
                    };
                }),
            };
        });

        this.initNextEnemy();

        this.floatingNumbers = new FloatingNumbersPlugin(this);

        this.drawLevel();

        this.enhancementDialog = PixelUI.add.enhancementDialog(this, {
            buttons: [
                { text: 'Reroll', value: 'reroll' },
                { text: '10 Gold', value: 'gold' },
            ],
            onSelect: (value: string) => {
                if (value === 'reroll') {
                } else if (value === 'gold') {
                    this.dataManager.saveGold(this.dataManager.gold + 10);
                }
            },
        });
        this.enhancementDialog.events.on('click', (data: IEnhancementData) => {
            this.character.buffs.add({
                ressource: data.ressource,
                type: data.type,
                time: data.time,
                trigger: data.trigger,
                value: data.value,
            });
        });

        this.time.delayedCall(1500, () => {
            this.enhancementDialog.open();
            this.shaker.shake();
        });

        /* this.time.delayedCall(10000, () => {
            this.enhancementDialog.open();
            this.children.bringToTop(this.enhancementDialog);
            this.shaker.shake();
        }); */

        this.anims.create({
            key: 'shield',
            frames: this.anims.generateFrameNumbers('shield', {
                start: 0,
                end: 9,
            }),
            frameRate: 10,
            repeat: 0,
        });

        this.anims.create({
            key: 'heal',
            frames: this.anims.generateFrameNumbers('heal', {
                start: 0,
                end: 5,
            }),
            frameRate: 10,
            repeat: 0,
        });

        this.anims.create({
            key: 'fire-column',
            frames: this.anims.generateFrameNumbers('fire-column', {
                start: 0,
                end: 7,
            }),
            frameRate: 10,
            repeat: 0,
        });

        this.anims.create({
            key: 'fire-column-appear',
            frames: this.anims.generateFrameNumbers('fire-column-appear', {
                start: 0,
                end: 8,
            }),
            frameRate: 10,
            repeat: 0,
        });

        this.anims.create({
            key: 'blast',
            frames: this.anims.generateFrameNumbers('blast', {
                start: 0,
                end: 10,
            }),
            frameRate: 10,
            repeat: 0,
        });

        this.anims.create({
            key: 'fire-block',
            frames: this.anims.generateFrameNumbers('fire-block', {
                start: 0,
                end: 19,
            }),
            frameRate: 20,
            repeat: -1,
        });

        this.anims.create({
            key: 'poison',
            frames: this.anims.generateFrameNumbers('poison', {
                start: 0,
                end: 4,
            }),
            frameRate: 5,
            repeat: -1,
        });
    }

    showDeadDialog(): void {
        // this.sfxAudioSprites.play('se_button_over');
        const dialog = PixelUI.add.dialog(this, 'You died', ['Upgrade your skills and come back, weakling', '', ''], {
            buttons: [{ text: 'Ok', value: 'ok' }],
            onSelect: (value) => {
                console.log(value);
                this.scene.stop('GameScene');
                this.scene.start('TitleScene');
            },
        });
        dialog.open();
    }

    initNextEnemy(): void {
        if (this.levels[this.levelCurrent]) {
            if (this.levels[this.levelCurrent].encounters[this.encounterCurrent]) {
                const encounter = this.levels[this.levelCurrent].encounters[this.encounterCurrent];
                const enemies: Enemy[] = encounter.enemies.filter((e) => !e.isDead);
                console.log('enemies', enemies);
                enemies.forEach((enemy: Enemy, index: number) => {
                    if (enemy) {
                        // enemy.setType(encounter.type);
                        enemy.setVisible(true);
                        //// enemy.setDepth(enemies.length - index);
                        const x = this.width - 80 * (enemies.length - index - 1) - enemy.width / 3;
                        const y = this.PADDING_TOP - this.BORDER_WIDTH * 1.2 - 50 * (enemies.length - index - 1);
                        enemy.setPositionXY(x, y);
                        enemy.character.tint = enemies.length > 1 ? (index === 1 ? 0xffffff : 0x555555) : 0xffffff; // index === 1 ? 0xcccccc : 0x555555;
                        enemy.events.on('died', () => {
                            if (this.levels[this.levelCurrent].encounters[this.encounterCurrent].enemies.filter((e) => !e.isDead).length === 0) {
                                this.encounterCurrent += 1;
                                this.initNextEnemy();
                                this.drawLevel();
                            }
                        });
                    }
                });
                this.children.bringToTop(this.character);
            } else {
                console.log('level ends');
                this.encounterCurrent = 0;
                this.levelCurrent += 1;
                this.initNextEnemy();
            }
        } else {
            console.log('game ends');
        }
    }

    drawLevel(): void {
        this.levelObjects.forEach((item: any) => {
            item.destroy();
        });
        if (this.levels[this.levelCurrent]) {
            if (this.background) {
                this.background.destroy();
            }
            this.background = this.add.sprite(0, 200, this.levels[this.levelCurrent].background);
            this.background.setOrigin(0, 0.5);
            this.background.setScale(1 / (2048 / this.width));
            this.background.setAlpha(0.5);
            this.children.sendToBack(this.background);
            this.levelObjects = [];
            const length = (this.width - this.BORDER_WIDTH * 2) / (this.levels[this.levelCurrent].encounters.length - 1);
            for (let i = 0; i < this.levels[this.levelCurrent].encounters.length - 1; i++) {
                const circle = this.add.circle(this.BORDER_WIDTH + length * i, this.BORDER_WIDTH, i === this.encounterCurrent ? 10 : 5, 0xffffff);
                this.add.line(0, 0, circle.x, circle.y, circle.x + length, circle.y, 0xffffff).setOrigin(0);
                this.levelObjects.push(circle);
            }
            this.add.circle(this.width - this.BORDER_WIDTH, this.BORDER_WIDTH, 5, 0xffffff);
            const goal = this.add
                .image(this.width - this.BORDER_WIDTH, this.BORDER_WIDTH - 15, 'goal')
                .setOrigin(0.5, 0.5)
                .setScale(0.2);
            goal.setInteractive();
            goal.on('pointerup', () => {
                console.log(this.dumpBoard());
            });
        }
    }

    drawBoard(): void {
        this.graphics.fillStyle(0xffffff, 0.2);
        this.graphics.fillRect(0, 0, this.BLOCK_SIZE + 4, this.BLOCK_SIZE + 4);
        this.graphics.generateTexture('cell', this.BLOCK_SIZE + 6, this.BLOCK_SIZE + 6);

        for (let i = 0; i < this.NUM_ROWS; i++) {
            for (let j = 0; j < this.NUM_COLS; j++) {
                const x = this.BORDER_WIDTH + 7 + j * (this.BLOCK_SIZE + 6);
                const y = this.PADDING_TOP + i * (this.BLOCK_SIZE + 6);

                this.add.image(x, y, 'cell');

                this.createBlock(x, y, {
                    variation: this.board.grid[i][j].variation,
                    row: i,
                    col: j,
                    timer: -1,
                });
            }
        }
    }

    createBlock(x, y, data): Block {
        let block: Block = this.blocks.getFirstDead(false, x, y);
        if (block == null) {
            block = new Block(this, x, y, data);
            // block.setScale(this.BLOCK_SCALE);

            this.input.setDraggable(block);
            this.blocks.add(block, true);
        } else {
            block.reset(x, y, data);
        }

        this.children.bringToTop(block);
        block.setActive(true);
        block.setVisible(true);
        return block;
    }

    getBlockFromColRow(block: { row: number; col: number } | Block): Block | undefined {
        return this.blocks.getChildren().find((item: Block) => item.row == block.row && item.col == block.col) as Block | undefined;
    }

    dropBlock(sourceRow, targetRow, col): void {
        const block: Block = this.getBlockFromColRow({
            row: sourceRow,
            col: col,
        });
        const targetY = this.PADDING_TOP + targetRow * (this.BLOCK_SIZE + 6);

        if (block) {
            block.row = targetRow;
            this.children.bringToTop(block);
            this.tweens.add({
                targets: block,
                y: targetY,
                duration: this.ANIMATION_TIME / 2,
                ease: 'Linear',
                onComplete: () => {
                    block.hasMoved();
                },
            });
        }
    }

    // drop blocks after match
    dropReserveBlock(sourceRow, targetRow, col): void {
        const x = this.BORDER_WIDTH + 7 + col * (this.BLOCK_SIZE + 6);
        const y = this.PADDING_TOP + -(this.BLOCK_SIZE + 6) * this.board.RESERVE_ROW + sourceRow * (this.BLOCK_SIZE + 6);

        const block = this.createBlock(x, y, {
            variation: this.board.grid[targetRow][col].variation,
            row: targetRow,
            col: col,
            timer: this.board.grid[targetRow][col].timer,
        });
        const targetY = this.PADDING_TOP + targetRow * (this.BLOCK_SIZE + 6);

        // light fuse of bomb
        if (this.board.grid[targetRow][col].variation === Block.SPECIAL_BOMB) {
            block.lightFuse();
            block.drawArrow();
        } else if (this.board.grid[targetRow][col].variation === Block.SPECIAL_CHEST) {
            block.drawArrow();
        } else if (this.board.grid[targetRow][col].variation === Block.SPECIAL_MUSHROOM) {
            block.drawArrow();
        }

        this.tweens.add({
            targets: block,
            y: targetY,
            duration: this.ANIMATION_TIME / 1.5,
            ease: 'Linear',
        });
    }

    swapBlocks(block1: Block, block2: Block): void {
        if (block2) {
            block1.select();
            this.tweens.add({
                targets: block1,
                x: block2.x,
                y: block2.y,
                duration: this.ANIMATION_TIME / 2,
                ease: 'Linear',
                onComplete: () => {
                    this.children.bringToTop(block1);

                    // update model
                    this.board.swap(block1, block2);

                    if (!this.isReversingSwap) {
                        const nextChains = this.board.findAllChains(block1 /* , block2 */);
                        if (nextChains.length) {
                            this.time.delayedCall(this.ANIMATION_TIME / 2, () => {
                                this.updateBoard(block1, block2);
                            });
                        } else {
                            this.isReversingSwap = true;
                            this.swapBlocks(block1, block2);
                        }
                    } else {
                        this.isReversingSwap = false;
                        this.clearSelection();
                    }
                },
            });

            this.tweens.add({
                targets: block2,
                x: block1.x,
                y: block1.y,
                duration: this.ANIMATION_TIME / 2,
                ease: 'Linear',
                onComplete: () => {
                    this.children.bringToTop(block2);
                },
            });
        }
    }

    pickBlock(block: Block): void {
        // only swap if the UI is not blocked
        if (this.isBoardBlocked) {
            return;
        }

        // dont move special blocks
        if (block.variation > 100) {
            return;
        }

        // if there is nothing selected
        if (!this.selectedBlock) {
            // highlight the first block
            block.select();
            this.selectedBlock = block;
        } else {
            // second block you are selecting is target block
            this.targetBlock = block;

            // only adjacent blocks can swap
            if (this.board.checkAdjacent(this.selectedBlock, this.targetBlock)) {
                // block the UI
                this.isBoardBlocked = true;

                // swap blocks
                this.swapBlocks(this.selectedBlock, this.targetBlock);
            } else {
                this.clearSelection();
            }
        }
    }

    clearSelection(): void {
        this.isBoardBlocked = false;
        if (this.selectedBlock) {
            this.selectedBlock.deselect();
        }
        this.selectedBlock = undefined;
        this.targetBlock = undefined;
    }

    updateBoard(block1: Block, block2: Block | undefined): void {
        const nextChains = this.board.getNextChains(block1 /* , block2 */);
        // console.log('nextChains', nextChains);
        nextChains.forEach((nextChain) => {
            if (nextChain && nextChain.items.length) {
                this.board.updateGrid();

                const texture = nextChain.items.reduce((prev: string, current: { texture: string; x: number; y: number }) => (prev < current.texture ? current.texture : prev), '');
                const hitCount = nextChain.items.reduce((prev: number, current: { texture: string; x: number; y: number }) => {
                    return prev + this.board.getRarity(current.texture);
                }, 1);
                const isChest = texture === 'block' + Block.SPECIAL_CHEST;
                const isChestPremium = texture === 'block102';
                const isAttackPhysical = texture === 'block1' || texture === 'block6' || texture === 'block11';
                const isShield = texture === 'block2' || texture === 'block7' || texture === 'block12';
                const isHealth = texture === 'block3' || texture === 'block8' || texture === 'block13';
                const isAttackMagic = texture === 'block4' || texture === 'block9' || texture === 'block14';
                const isMovable = !isChest && !isChestPremium;

                // TODO
                const multi = nextChain.items.reduce((previous: number, current: any) => {
                    const rarity = this.board.getRarity(current.texture);
                    if (rarity) {
                        return previous + 0.66 * rarity;
                    }
                    return Phaser.Math.RoundTo(previous + 0.33, -2);
                }, 0.0);

                if (isChest || isChestPremium) {
                    console.log('chest', texture);
                    this.children.bringToTop(this.enhancementDialog);
                    this.enhancementDialog.setDepth(12);
                    this.enhancementDialog.open();
                }

                nextChain.items.forEach((blockData) => {
                    const enemy = [...this.levels[this.levelCurrent].encounters[this.encounterCurrent].enemies].reverse().find((e) => !e.isDead);
                    const blockClone = new Phaser.GameObjects.Image(this, blockData.x, blockData.y, blockData.texture);
                    if (isMovable && enemy) {
                        blockClone.setScale(this.BLOCK_SCALE);
                        //// blockClone.setDepth(11);
                        this.add.existing(blockClone);
                        this.tweens.add({
                            targets: [blockClone],
                            props: {
                                x: {
                                    value: () => {
                                        return (isAttackPhysical || isAttackMagic ? enemy.xOrigin : this.character.xOrigin) + Phaser.Math.Between(-5, 5);
                                    },
                                    ease: 'Power1',
                                },
                                y: {
                                    value: () => {
                                        return (isAttackPhysical || isAttackMagic ? enemy.yOrigin - enemy.height / 2 : this.character.yOrigin - this.character.height / 2) + Phaser.Math.Between(-5, 5);
                                    },
                                    ease: 'Power3',
                                },
                            },
                            scale: 1,
                            duration: 500,
                            yoyo: false,
                            repeat: 0,
                            onComplete: (tween, blockItems) => {
                                tween.targets.forEach((b: any) => {
                                    b.setActive(false);
                                    b.setVisible(false);
                                    b.destroy(true);
                                });
                            },
                        });
                    }
                    this.tweens.add({
                        targets: [blockClone],
                        alpha: 0.1,
                        yoyo: false,
                        repeat: 0,
                        duration: 500,
                        ease: 'Sine.easeInOut',
                    });
                });

                // console.log('hitCount', hitCount);

                for (let i = 0; i < hitCount; i++) {
                    const enemy = [...this.levels[this.levelCurrent].encounters[this.encounterCurrent].enemies].reverse().find((e) => !e.isDead);
                    if (isAttackPhysical) {
                        this.character.attack(enemy, multi, 'physical');
                    } else if (isAttackMagic) {
                        this.character.attack(enemy, multi, 'magical');
                    } else if (isShield) {
                        this.character.shielding(block1, multi);
                    } else if (isHealth) {
                        this.character.heal(block1, multi);
                    }
                }
            }
            /*  else {
                this.time.delayedCall(this.ANIMATION_TIME, () => {
                    this.nextTurn();
                });
            } */
        });

        if (nextChains.length === 0) {
            this.time.delayedCall(this.ANIMATION_TIME, () => {
                this.nextTurn();
            });
        } else {
            // after the dropping has ended
            this.time.delayedCall(this.ANIMATION_TIME, () => {
                // see if there are new chains
                const nextChains = this.board.findAllChains(undefined);
                if (nextChains.length) {
                    this.updateBoard(nextChains[0] as unknown as Block, undefined);
                } else {
                    this.clearSelection();
                    this.nextTurn();
                }
            });
        }
    }

    nextTurn(): void {
        if (this.character.isDead) {
            return;
        }

        const enemies = this.levels[this.levelCurrent].encounters[this.encounterCurrent].enemies.filter((e) => !e.isDead);
        enemies.forEach((enemy: Enemy) => {
            enemy.nextAttackIn -= 1;
            if (enemy.nextAttackIn < 0) {
                enemy.attack(this.character, 1.0);
                this.shaker.shake();
                /* this.time.delayedCall(this.ANIMATION_TIME * 2, () => {
                    enemy.nextAttackIn = enemy.enemyData.attackEvery - 1;
                }); */
            }
        });

        // update countdowns
        for (let i = 0; i < this.NUM_ROWS; i++) {
            for (let j = 0; j < this.NUM_COLS; j++) {
                if (this.board.grid[i][j].status === 'fire') {
                    const block: Block | undefined = this.getBlockFromColRow({ row: i, col: j });
                    this.orderManager.registerMove(block, this.character, 200, 'burn', () => {
                        this.character.damage(null, 3);
                    });
                } else if (this.board.grid[i][j].status === 'poison') {
                    const block: Block | undefined = this.getBlockFromColRow({ row: i, col: j });
                    this.orderManager.registerMove(block, this.character, 200, 'poison', () => {
                        this.character.damage(null, 3);
                    });
                }

                // timers
                if (this.board.grid[i][j].timer > -1) {
                    const block: Block | undefined = this.getBlockFromColRow({ row: i, col: j });
                    this.board.grid[i][j].timer -= 1;
                    if (block) {
                        block.setTimer(this.board.grid[i][j].timer);
                        if (this.board.grid[i][j].timer === -1) {
                            const blockObject: Block | undefined = this.getBlockFromColRow(block);
                            const variation = this.board.grid[i][j].variation;
                            this.board.grid[i][j].variation = 0;
                            if (blockObject) {
                                blockObject.kill();
                                this.board.updateGrid();

                                if (variation === Block.SPECIAL_BOMB) {
                                    this.damageSurroundingBlocks(blockObject, i, j, 'blast', 'fire', 1, 50, 'fire-block');
                                } else if (variation === Block.SPECIAL_MUSHROOM) {
                                    this.damageSurroundingBlocks(blockObject, i, j, 'blast', 'poison', 1, 50, 'poison');
                                }
                            }
                        }
                    }
                }
            }
        }

        this.board.updateGrid();
    }

    damageSurroundingBlocks(blockObject: Block, i: number, j: number, effect: string, status: string, radius: number, percentage: number, blockTexture: string): void {
        this.shaker.shake();

        const animation = this.add.sprite(blockObject.x, blockObject.y, effect);
        animation.setOrigin(0.5, 0.5);
        animation.setDepth(105);
        animation.anims.play(effect, false).once('animationcomplete', () => {
            animation.destroy();
        });

        // Affect surrounding blocks
        const blocksToTaint = this.board.getSurroundingBlocks({ row: i, col: j }, radius, percentage);
        blocksToTaint.forEach((blockToTaint) => {
            // eslint-disable-next-line prettier/prettier
            if (this.board.grid[blockToTaint.row][blockToTaint.col].variation < Block.SPECIAL_CHEST) {
                this.board.grid[blockToTaint.row][blockToTaint.col].status = status;
                const blockToBurnObject: Block | undefined = this.getBlockFromColRow(blockToTaint);
                if (blockToBurnObject) {
                    this.time.delayedCall(Phaser.Math.Between(50, 200), () => {
                        blockToBurnObject.changeStatus(blockTexture);
                    });
                }
            }
        });
    }

    public dumpBoard(): string {
        return this.board.dumpBoard();
    }

    public update(): void {
        this.debugInfo.update();
    }
}
