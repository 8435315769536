import GameScene from '../scenes/GameScene';
import BaseCharacter from './BaseCharacter';
import Block from './Block';

export default class OrderManager extends Phaser.GameObjects.Container {
    public scene: GameScene;

    public isBlocked: boolean = false;
    public isBlockedAnimation: boolean = false;
    public frameTime: number = 0;
    public queue: Array<{
        type: string;
        source: any;
        dest: BaseCharacter;
        delay: number;
        origin: { x: number; y: number };
        callback: Function;
    }> = [];
    public queueAnimation: Array<{
        type: string;
        dest: { x: number; y: number };
        delay: number;
        callback: Function;
    }> = [];

    constructor(scene: GameScene) {
        super(scene, 0, 0, []);
        this.scene = scene;
        // eslint-disable-next-line @typescript-eslint/unbound-method
        this.scene.events.on('update', this.update, this);
    }

    update(time, delta): void {
        this.frameTime += delta;
        const every = 100;
        while (this.frameTime > every) {
            this.frameTime -= every;

            if (this.queueAnimation.length && !this.isBlockedAnimation) {
                const job = this.queueAnimation.pop();
                this.isBlockedAnimation = true;
                this.scene.time.delayedCall(job.delay, () => {
                    const animation = this.scene.add.sprite(job.dest.x, job.dest.y, job.type);
                    animation.setOrigin(0.5, 0.5);
                    animation.anims.play(job.type, false).once('animationcomplete', () => {
                        animation.destroy();
                    });
                    this.isBlockedAnimation = false;
                    job.callback();
                });
            }

            if (this.queue.length && !this.isBlocked) {
                const job = this.queue.pop();
                this.isBlocked = true;

                if (job?.type === 'physical') {
                    this.scene.tweens.add({
                        targets: [job.source],
                        props: {
                            x: {
                                value: (): unknown => {
                                    return job.dest.x + (job.origin.x < job.dest.x ? -(job.dest.width / 2) : job.dest.width / 2);
                                },
                                ease: 'Power1',
                            },
                            y: {
                                value: (): unknown => {
                                    return job.dest.y;
                                },
                                ease: 'Power3',
                            },
                        },
                        duration: 100,
                        yoyo: false,
                        repeat: 0,
                        onComplete: () => {
                            this.scene.shaker.shake();

                            job.dest.damageIndicate();

                            this.scene.tweens.add({
                                targets: [job.source],
                                props: {
                                    x: {
                                        value: (): unknown => {
                                            return job.origin.x;
                                        },
                                        ease: 'Power3',
                                    },
                                    y: {
                                        value: (): unknown => {
                                            return job.origin.y;
                                        },
                                        ease: 'Power1',
                                    },
                                },
                                duration: 200,
                                yoyo: false,
                                repeat: 0,
                                onComplete: () => {
                                    this.isBlocked = false;
                                },
                            });

                            job.callback();
                        },
                    });
                } else if (job?.type === 'magical') {
                    job.dest.damageIndicate();

                    const animationAppear = this.scene.add.sprite(job.dest.x, 250 + 80, 'fire-column-appear');
                    animationAppear.setOrigin(0.5, 0.5);
                    // animationAppear.setDepth(11);
                    animationAppear.anims.play('fire-column-appear', false).once('animationcomplete', () => {
                        animationAppear.destroy();
                    });
                    this.scene.time.delayedCall(100, () => {
                        const animation = this.scene.add.sprite(job.dest.x, 250 - 80, 'fire-column');
                        animation.setOrigin(0.5, 0.5);
                        // animation.setDepth(11);
                        animation.anims.play('fire-column', false).once('animationcomplete', () => {
                            animation.destroy();
                            this.isBlocked = false;
                        });
                        job.callback();
                    });
                } else if (job?.type === 'bomb') {
                    const colDest = Phaser.Math.Between(0, this.scene.NUM_COLS - 1);
                    const blockObject: Block | undefined = this.scene.getBlockFromColRow({ row: 0, col: colDest });
                    if (blockObject) {
                        this.scene.board.reserveGrid[this.scene.NUM_ROWS - 1][colDest] = Block.SPECIAL_BOMB;
                        this.scene.board.grid[0][colDest].variation = 0;
                        blockObject.kill();
                        this.scene.board.updateGrid();
                        this.isBlocked = false;
                        job.callback();
                    }
                } else if (job?.type === 'mushroom') {
                    const colDest = Phaser.Math.Between(0, this.scene.NUM_COLS - 1);
                    const blockObject: Block | undefined = this.scene.getBlockFromColRow({ row: 0, col: colDest });
                    if (blockObject) {
                        this.scene.board.reserveGrid[this.scene.NUM_ROWS - 1][colDest] = Block.SPECIAL_MUSHROOM;
                        this.scene.board.grid[0][colDest].variation = 0;
                        blockObject.kill();
                        this.scene.board.updateGrid();
                        this.isBlocked = false;
                        job.callback();
                    }
                } else if (job?.type === 'burn') {
                    const statusClone = this.scene.add
                        .sprite(job.source.x, job.source.y - job.source.height / 2, job.source.animation.texture)
                        .setScale(this.scene.BLOCK_SCALE)
                        .setDepth(11);
                    this.scene.tweens.add({
                        targets: [statusClone],
                        props: {
                            x: {
                                value: (): number => {
                                    return job.dest.x + Phaser.Math.Between(-5, 5);
                                },
                                ease: 'Power1',
                            },
                            y: {
                                value: (): number => {
                                    return job.dest.y - job.dest.height * job.dest.SCALE + Phaser.Math.Between(-5, 5);
                                },
                                ease: 'Power3',
                            },
                        },
                        scale: 1,
                        duration: 500,
                        yoyo: false,
                        repeat: 0,
                        onComplete: (tween) => {
                            this.isBlocked = false;
                            job.callback();
                            tween.targets.forEach((b: any) => {
                                b.setActive(false);
                                b.setVisible(false);
                                b.destroy(true);
                            });
                        },
                    });
                    this.scene.tweens.add({
                        targets: [statusClone],
                        alpha: 0.1,
                        yoyo: false,
                        repeat: 0,
                        duration: 500,
                        ease: 'Sine.easeInOut',
                    });
                } else if (job?.type === 'poison') {
                    const statusClone = this.scene.add
                        .sprite(job.source.x, job.source.y - job.source.height / 2, job.source.animation.texture)
                        .setScale(this.scene.BLOCK_SCALE)
                        .setDepth(11);
                    this.scene.tweens.add({
                        targets: [statusClone],
                        props: {
                            x: {
                                value: (): number => {
                                    return job.dest.x + Phaser.Math.Between(-5, 5);
                                },
                                ease: 'Power1',
                            },
                            y: {
                                value: (): number => {
                                    return job.dest.y - job.dest.height * job.dest.SCALE + Phaser.Math.Between(-5, 5);
                                },
                                ease: 'Power3',
                            },
                        },
                        scale: 1,
                        duration: 500,
                        yoyo: false,
                        repeat: 0,
                        onComplete: (tween) => {
                            this.isBlocked = false;
                            job.callback();
                            tween.targets.forEach((b: any) => {
                                b.setActive(false);
                                b.setVisible(false);
                                b.destroy(true);
                            });
                        },
                    });
                    this.scene.tweens.add({
                        targets: [statusClone],
                        alpha: 0.1,
                        yoyo: false,
                        repeat: 0,
                        duration: 500,
                        ease: 'Sine.easeInOut',
                    });
                }
            }
        }
    }

    registerMove(source: any, dest: any, delay: number, type, callback: Function): void {
        this.queue.push({
            origin: {
                x: source.xOrigin,
                y: source.yOrigin,
            },
            source,
            dest,
            delay,
            type,
            callback,
        });
    }

    registerAnimation(block: any, dest: any, xPadding: number, yPadding: number, type: string, delay: number, callback: Function): void {
        this.queueAnimation.push({
            dest: {
                x: dest.x + xPadding,
                y: dest.y + yPadding,
            },
            delay,
            type,
            callback,
        });
    }
}
