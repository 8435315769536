import ISkillData from './ISkillData';

const skills: { [key: string]: ISkillData } = {
    skill01: {
        key: 'skill-01',
        damage: [20, 40],
        type: 'physical',
        attackIn: 3,
    },
    skill08: {
        key: 'skill-08',
        damage: [0],
        type: 'bomb',
        attackIn: 3,
    },
    skill24: {
        key: 'skill-24',
        damage: [12, 25],
        type: 'physical',
        attackIn: 3,
    },
    skill27: {
        key: 'skill-27',
        damage: [25],
        type: 'physical',
        attackIn: 3,
    },
    skill54: {
        key: 'skill-54',
        damage: [0],
        type: 'mushroom',
        attackIn: 1,
    },
    skill56: {
        key: 'skill-56',
        damage: [35],
        type: 'physical',
        attackIn: 3,
    },
    skill59: {
        key: 'skill-59',
        damage: [25, 40],
        type: 'magical',
        attackIn: 3,
    },
    skill80: {
        key: 'skill-80',
        damage: [25, 40],
        type: 'physical',
        attackIn: 3,
    },
    skill95: {
        key: 'skill-95',
        damage: [25, 60],
        type: 'physical',
        attackIn: 3,
    },
};

export default skills;
