import IBuffData from '../data/IBuffData';
import GameScene from '../scenes/GameScene';

export default class CharacterBuffItem extends Phaser.GameObjects.Container {
    public scene: GameScene;
    public background: Phaser.GameObjects.Rectangle;
    public backgroundItem: Phaser.GameObjects.Rectangle;
    public border: Phaser.GameObjects.Graphics;
    public item: Phaser.GameObjects.Image;
    public itemData: IBuffData;
    public events: Phaser.Events.EventEmitter = new Phaser.Events.EventEmitter();

    constructor(scene: GameScene, x: number, y: number, width: number, height: number, data: IBuffData) {
        super(scene, x, y, []);

        const iconSizeX: number = 25;
        const iconSizeY: number = 25;

        const item = new Phaser.GameObjects.Image(scene, -(iconSizeX / 2), -(iconSizeY / 2), data.ressource);
        const border = new Phaser.GameObjects.Graphics(scene);
        const background = new Phaser.GameObjects.Rectangle(scene, -(iconSizeX / 2) - 2, -(iconSizeY / 2) - 2, iconSizeX + 4, iconSizeY + 4, 0xffffff, 0.7);

        this.add(background);
        this.add(border);
        this.add(item);

        this.scene = scene;
        this.itemData = data;
        this.item = item;
        this.background = background;
        this.border = border;

        this.item.setScale(0.2);
        this.setSize(iconSizeX, iconSizeX);
        this.item.setInteractive();
        this.item.setOrigin(0.5, 0.5);

        this.border.lineStyle(10, 0xffffff, 1);
        this.border.strokeRoundedRect(-iconSizeX - 2, -iconSizeY - 2, iconSizeX + 4, iconSizeY + 5, 5);

        this.item.on('pointerup', () => {
            console.log('pointerup');
            // this.events.emit('click', data);
        });

        scene.add.existing(this);
    }
}
