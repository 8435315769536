import GameScene from '../scenes/GameScene';

export default class HealthBar extends Phaser.GameObjects.Container {
    public scene: GameScene;
    public width: number;
    public step: number;
    public borderWidth: number;
    public showBorder: boolean;
    public barHeight: number;
    public value: number;
    public maxValue: number;
    public barNormal: Phaser.GameObjects.Rectangle;
    public barGhost: Phaser.GameObjects.Rectangle;
    public barBorder: Phaser.GameObjects.Rectangle;
    public color: number;
    public backgroundColor: number;
    public backgroundAlpha: number;
    public currentText: Phaser.GameObjects.Text;
    public events: Phaser.Events.EventEmitter;

    constructor(scene: GameScene, x, y, value, maxValue, width, barHeight, color, backgroundColor: number, backgroundAlpha: number, showText: boolean, showBorder: boolean) {
        const barNormal = new Phaser.GameObjects.Rectangle(scene, x, y, width, barHeight, color);
        const barGhost = new Phaser.GameObjects.Rectangle(scene, x, y, width, barHeight, color, 0.5);
        const barBorder = new Phaser.GameObjects.Rectangle(scene, x - 2, y - 2, width + 4, barHeight + 4, 0x000000, 0.3);

        super(scene, x, y, [barBorder, barGhost, barNormal]);

        const currentText = new Phaser.GameObjects.Text(scene, x, y, value + ' / ' + maxValue, {
            font: '18px Arial',
            fill: '#FFFFFF',
            stroke: '#000000',
            strokeThickness: 3,
            align: 'left',
        } as Phaser.Types.GameObjects.Text.TextStyle);

        if (showText) {
            this.add(currentText);
        }

        this.scene = scene;
        this.width = width;
        this.barNormal = barNormal;
        this.barGhost = barGhost;
        this.barBorder = barBorder;
        this.events = new Phaser.Events.EventEmitter();

        this.color = color;
        this.barHeight = barHeight;
        this.backgroundColor = backgroundColor;
        this.backgroundAlpha = backgroundAlpha;
        this.currentText = currentText;
        this.showBorder = showBorder;
        this.borderWidth = 2;

        this.currentText.setOrigin(0.5, 0.5);

        this.step = (this.width - this.borderWidth * 2) / maxValue;

        this.x = x - this.width / 4;
        this.y = y;
        this.value = value;
        this.maxValue = maxValue;

        this.scene.add.existing(this);
    }

    setPositionY(value: number): void {
        this.y = value;
        this.currentText.setPosition(this.width / 4, this.y - this.barHeight / 2);
        this.barNormal.setPosition(this.width / 4, this.y);
        this.barGhost.setPosition(this.width / 4, this.y);
        this.barBorder.setPosition(this.width / 4, this.y);
    }

    addToValue(amount): void {
        this.value += amount;
        if (this.value > this.maxValue) {
            this.value = this.maxValue;
        }
        if (this.value < 0) {
            this.value = 0;
        }

        if (amount > 0) {
            this.barGhost.setSize(this.step * this.value, this.barHeight);
            this.scene.tweens.add({
                targets: this.barNormal,
                props: {
                    width: {
                        value: (): unknown => {
                            return this.step * this.value;
                        },
                        ease: 'Power1',
                    },
                },
                duration: 500,
            });
        } else {
            this.barNormal.setSize(this.step * this.value, this.barHeight);
            this.scene.tweens.add({
                targets: this.barGhost,
                props: {
                    width: {
                        value: (): unknown => {
                            return this.step * this.value;
                        },
                        ease: 'Power1',
                    },
                },
                duration: 500,
            });
        }

        this.currentText.setText(this.value.toString() + ' / ' + this.maxValue.toString());
    }

    set(value): void {
        this.value = value;
        this.barNormal.setSize(this.step * this.value, this.barHeight);
        this.barGhost.setSize(this.step * this.value, this.barHeight);
        this.currentText.setText(this.value.toString() + ' / ' + this.maxValue.toString());
    }

    setMax(value): void {
        this.maxValue = value;
        this.step = (this.width - this.borderWidth * 2) / value;
        this.barNormal.setSize(this.step * this.value, this.barHeight);
        this.barGhost.setSize(this.step * this.value, this.barHeight);
        this.currentText.setText(this.value.toString() + ' / ' + this.maxValue.toString());
    }
}
