import GameScene from '../scenes/GameScene';
import BuffManager from './BuffManager';
import HealthBar from './HealthBar';
import OutlinePipelinePlugin from 'phaser3-rex-plugins/plugins/outlinepipeline-plugin';

export default class BaseCharacter extends Phaser.GameObjects.Container {
    public scene: GameScene;

    public SCALE: number = 0.5;
    public scaleCharacter: number;
    public multiCharacter: number;

    public frameTime: number = 0;
    public breathTime: number = 500;

    public buffs: BuffManager;
    public character: Phaser.GameObjects.Image;
    public background: Phaser.GameObjects.Rectangle;
    public healthBar: HealthBar;
    public shieldBar: HealthBar;
    public cardScale: number = 0.15;
    public key: string;
    public isDead: boolean = false;
    public maxHealth: number = 100;
    public maxShield: number = 100;
    public birthdate: Date = new Date();
    public strength: number = 10;
    private _health: number = 100;
    private _shield: number = 0;
    public events: Phaser.Events.EventEmitter = new Phaser.Events.EventEmitter();
    public xOrigin: number = 0;
    public yOrigin: number = 0;

    protected outlinePlugin: OutlinePipelinePlugin;

    get health(): number {
        return this._health;
    }
    set health(value: number) {
        this._health = value;
    }

    get shield(): number {
        return this._shield;
    }
    set shield(value: number) {
        this._shield = value;
    }

    constructor(scene: GameScene, x: number, y: number, elements: Phaser.GameObjects.GameObject[], assetName: string) {
        super(scene, x, y, [...elements]);
        const character = new Phaser.GameObjects.Image(scene, 0, 0, assetName);
        const healthBar = new HealthBar(scene, 0, 0, 100, 100, 150, 12, 0xff0000a, 0xffffff, 1, true, true);
        const shieldBar = new HealthBar(scene, 0, 0, 0, 100, 150, 4, 0xffffff, 0xffffff, 0, false, false);

        this.add(character);
        this.add(healthBar);
        this.add(shieldBar);

        this.buffs = new BuffManager();

        this.scaleCharacter = this.SCALE;
        this.multiCharacter = 1;
        this.scene = scene;
        this.healthBar = healthBar;
        this.shieldBar = shieldBar;
        this.character = character;
        this.xOrigin = x;
        this.yOrigin = y;

        this.breathTime = Phaser.Math.Between(950, 1050);
        // this.breathe();

        this.character.setOrigin(0.5, 1);
        this.character.setScale(this.scaleCharacter);
        this.setSize(this.character.width * this.SCALE, this.character.height * this.SCALE);
        // this.healthBar.x = -(this.healthBar.width * this.scale);

        this.healthBar.setPositionY(0);
        this.shieldBar.setPositionY(0 + 6);
        // this.healthBar.setPosition(this.healthBar.x, this.healthBar.y);
        // this.healthBar.draw();
        // this.shieldBar.setPosition(-60, -(this.character.height * 1)+20);
        this.shieldBar.set(this.shield);

        this.outlinePlugin = this.scene.plugins.get('rexOutlinePipeline') as OutlinePipelinePlugin;
        this.outlinePlugin.add(this.character, {
            thickness: 3.0,
            outlineColor: 0x000000,
            quality: 1.0,
            name: 'rexOutlinePostFx',
        });

        // eslint-disable-next-line @typescript-eslint/unbound-method
        this.scene.events.on('update', this.update, this);
    }

    update(time, delta): void {
        this.frameTime += delta;
        const every = this.breathTime * 2;
        while (this.frameTime > every) {
            this.frameTime -= every;
            this.breathe();
        }
    }

    breathe(): void {
        if (this.scene) {
            this.scene.tweens.add({
                targets: this.character,
                scaleY: this.scaleCharacter + this.scaleCharacter * 0.01,
                ease: 'Linear',
                duration: this.breathTime,
                onComplete: () => {
                    this.scene.tweens.add({
                        targets: this.character,
                        scaleY: this.scaleCharacter - this.scaleCharacter * 0.01,
                        ease: 'Linear',
                        duration: this.breathTime,
                    });
                },
            });
        }
    }

    die(): void {
        this.isDead = true;
        console.log('die');
    }

    setPositionXY(x: number, y: number): void {
        this.setPosition(x, y);
        this.xOrigin = x;
        this.yOrigin = y;
    }

    addShield(shield: number): void {
        if (this.shield + shield > this.maxHealth) {
            this.shield = this.maxHealth;
            this.shieldBar.set(shield);
        } else {
            this.shield += shield;
            this.shieldBar.addToValue(shield);

            if (this.scene.floatingNumbers) {
                this.scene.floatingNumbers.createFloatingText({
                    textOptions: {
                        fontFamily: 'arial',
                        fontSize: 50,
                        color: '#FFFFFF',
                        strokeThickness: 4,
                        fontWeight: '800',
                        stroke: '#000000',
                    },
                    text: shield,
                    timeToLive: 800,
                    align: 'top-center',
                    parentObject: this,
                    animation: 'up',
                    animationEase: 'Linear',
                });
            }
        }
    }

    addHealth(health: number): void {
        if (this.health + health > this.maxHealth) {
            this.health = this.maxHealth;
            this.healthBar.set(this.maxHealth);
        } else {
            this.health += health;
            this.healthBar.addToValue(health);

            if (this.scene.floatingNumbers) {
                this.scene.floatingNumbers.createFloatingText({
                    textOptions: {
                        fontFamily: 'arial',
                        fontSize: 50,
                        color: '#00FF00',
                        strokeThickness: 4,
                        fontWeight: '800',
                        stroke: '#000000',
                    },
                    text: health,
                    timeToLive: 800,
                    align: 'top-center',
                    parentObject: this,
                    animation: 'up',
                    animationEase: 'Linear',
                });
            }
        }
    }

    attack(destination: BaseCharacter, multi: number, type: string, damage: number): void {
        this.scene.orderManager.registerMove(this, destination, 500, type, () => {
            destination.damage(this, Math.ceil(damage * multi));
        });
    }

    damageIndicate(): void {
        this.character.setTintFill(0xffffff);
        this.scene.time.delayedCall(100, () => {
            this.character.clearTint();
        });
    }

    damage(source: BaseCharacter | undefined, damage: number): void {
        if (damage) {
            if (this.shield > 0 && this.shield > damage) {
                this.shield -= damage;
                this.shieldBar.addToValue(-damage);
            } else if (this.shield > 0 && this.shield <= damage) {
                this.health -= damage - this.shield;
                this.healthBar.addToValue(-(damage - this.shield));
                this.shield = 0;
                this.shieldBar.set(0);
            } else {
                this.health -= damage;
                this.healthBar.addToValue(-damage);
            }

            if (this.health <= 0 && !this.isDead) {
                this.die();
            }

            if (this.scene.floatingNumbers) {
                this.scene.floatingNumbers.createFloatingText({
                    textOptions: {
                        fontFamily: 'arial',
                        fontSize: 50,
                        color: '#ff0000',
                        strokeThickness: 4,
                        fontWeight: '800',
                        stroke: '#000000',
                    },
                    text: damage,
                    timeToLive: 800,
                    align: 'top-center',
                    parentObject: this,
                    animation: 'up',
                    animationEase: 'Linear',
                });
            }
        }
    }
}
