import { Scene } from 'phaser';
import IEnhancementData from '../data/IEnhancementData';
import TagText from 'phaser3-rex-plugins/plugins/tagtext.js';

export default class EnhancementItem extends Phaser.GameObjects.Container {
    public scene: Scene;
    public background: Phaser.GameObjects.Rectangle;
    public backgroundItem: Phaser.GameObjects.Rectangle;
    public border: Phaser.GameObjects.Graphics;
    public item: Phaser.GameObjects.Image;
    public title: Phaser.GameObjects.Text;
    public description: TagText;
    public events: Phaser.Events.EventEmitter = new Phaser.Events.EventEmitter();
    public key: string;
    public textOptions: Phaser.Types.GameObjects.Text.TextStyle = {
        font: 'bold 35px Arial',
        fill: '#111',
    } as Phaser.Types.GameObjects.Text.TextStyle;
    public textOptionsGreen: Phaser.Types.GameObjects.Text.TextStyle = {
        font: 'bold 35px Arial',
        fill: '#228B22',
    } as Phaser.Types.GameObjects.Text.TextStyle;
    public textOptionsRed: Phaser.Types.GameObjects.Text.TextStyle = {
        font: 'bold 35px Arial',
        fill: '#FF0000',
    } as Phaser.Types.GameObjects.Text.TextStyle;

    constructor(scene: Scene, x: number, y: number, width: number, height: number, data: IEnhancementData, key: string, quantity?: number) {
        super(scene, x, y, []);

        const iconSizeX: number = 128;
        const iconSizeY: number = 128;
        const cellSizeX: number = width;
        const cellSizeY: number = height;

        const rarityColor = data.rarity === 1 ? 0x1eff0c : data.rarity === 2 ? 0x0070ff : data.rarity === 3 ? 0xa335ee : 0xffffff;
        /*
        Poor – #889D9D
        Common – #FFFFFF
        Uncommon – #1EFF0C
        Rare – #0070FF
        Superior – #A335EE
        Legendary – #FF8000
        Heirloom – #E6CC80
        */
        const item = new Phaser.GameObjects.Image(scene, -(iconSizeX / 2), -(iconSizeY / 2), key);
        const border = new Phaser.GameObjects.Graphics(scene);
        const background = new Phaser.GameObjects.Rectangle(scene, -(iconSizeX / 2), -(iconSizeY / 2), iconSizeX, iconSizeY, 0xffffff, 0.7);
        const backgroundItem = new Phaser.GameObjects.Rectangle(scene, iconSizeX / 2 + 5, -(iconSizeY / 2), cellSizeX + 10, cellSizeY, 0x000000, 0.7);
        const title = new Phaser.GameObjects.Text(scene, 20, -iconSizeX + 15, data.title, {
            font: 'bold 35px Arial',
            fill: '#' + rarityColor.toString(16).padStart(6, '0'),
        } as Phaser.Types.GameObjects.Text.TextStyle);

        let desc = data.description.replace('${value}', data.value.toString());
        if (data.triggerValue) {
            desc = desc.replace('${triggerValue}', data.triggerValue.toString());
        }
        const description = new TagText(scene, 20, -(iconSizeX / 3), desc, {
            fontFamily: 'Arial',
            fontSize: '25px',
            wrap: {
                width: 250,
                mode: 'word',
            },
            tags: {
                tag0: {
                    color: 'white',
                    stroke: {},
                },
                tag1: {
                    color: 'red',
                    stroke: {},
                },
            },
        });

        this.add([backgroundItem, border, item, title, description]);

        this.scene = scene;
        this.item = item;
        this.background = background;
        this.backgroundItem = backgroundItem;
        this.border = border;
        this.title = title;
        this.description = description;
        this.key = key;

        this.item.setScale(0.5);
        this.setSize(iconSizeX, iconSizeX);
        this.backgroundItem.setInteractive();
        this.title.setOrigin(0, 0.5);
        this.description.setOrigin(0, 0.5);

        if (quantity !== undefined) {
            this.setQuantity(quantity);
        }
        this.item.setVisible(this.key ? true : false);

        this.border.lineStyle(10, rarityColor, 1);
        this.border.strokeRoundedRect(-iconSizeX, -iconSizeX, iconSizeX, iconSizeX, 5);

        this.backgroundItem.on('pointerup', () => {
            this.events.emit('click', data);
        });

        // scene.add.existing(this);
    }

    setTint(value: number): void {
        this.item.setTint(value);
    }

    setRequired(value: number): void {
        if (this.key) {
            this.title.setText(value.toFixed(0));
        }
    }

    setQuantity(value: number): void {
        if (this.key) {
            this.description.setText(value.toFixed(0));
        }
    }

    setRequiredColor(value: string): void {
        this.title.setStyle(value === 'red' ? this.textOptionsRed : value === 'green' ? this.textOptionsGreen : this.textOptions);
    }
}
