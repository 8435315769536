import GameScene from '../scenes/GameScene';
import BaseCharacter from './BaseCharacter';
import Block from './Block';
import CharacterBuffs from './CharacterBuffs';

export default class Character extends BaseCharacter {
    public scene: GameScene;

    public characterBuffs: CharacterBuffs;

    constructor(scene: GameScene, x: number, y: number) {
        super(scene, x, y, [], 'ch01');

        this.scene = scene;
        this.characterBuffs = new CharacterBuffs(scene, this.buffs);

        // this.setScale(0.5);
        // this.character.setScale(this.scale);
        // this.scale = this.SCALE * 2;
        //// this.setDepth(10);
        this.breathe();

        this.scene.add.existing(this);
    }

    heal(block: Block, multi: number): void {
        this.scene.orderManager.registerAnimation(block, this, 0, -60 - this.height / 2, 'heal', 500, () => {
            const health = Phaser.Math.Between(this.strength - this.strength / 10, this.strength + this.strength / 10);
            this.addHealth(Math.ceil(health * multi));
        });
    }

    shielding(block: Block, multi: number): void {
        this.scene.orderManager.registerAnimation(block, this, 0, -60 - this.height / 2, 'shield', 500, () => {
            const shield = 10;
            this.addShield(Math.ceil(shield * multi));
        });
    }

    attack(destination: BaseCharacter | undefined, multi: number, type: string): void {
        if (destination && !destination.isDead) {
            this.scene.time.delayedCall(500, () => {
                const damage = Phaser.Math.Between((this.strength - this.strength / 10) * multi, (this.strength + this.strength / 10) * multi);
                super.attack(destination, multi, type, damage);
            });
        }
    }

    /* attackMagic(destination: BaseCharacter | undefined, multi: number) {
        if (destination && !destination.isDead) {
            this.scene.time.delayedCall(500, () => {
                super.attackMagic(destination, multi);
            });
        }
    } */

    die(): void {
        super.die();
        console.log('character died');
        this.scene.showDeadDialog();
    }
}
