import * as PixelUI from '..';
import { TextLabelFactory } from './TextLabel';
import logger from '@/logger';
import { ComponentBase } from '../ComponentBase';
import { BackdropFactory } from './Backdrop';
import { ButtonFactory } from './Button';

export class LootboxDialog extends Phaser.GameObjects.Container {
    // private items: LootboxItem[];
    public events: Phaser.Events.EventEmitter;

    private dialog: ComponentBase;
    private backdrop: PixelUI.Backdrop;
    private buttons: PixelUI.Button[];

    private chest: Phaser.GameObjects.Image;
    private card: Phaser.GameObjects.Image;

    private dialogWidth: number;
    private dialogHeight: number;
    private cardScale: number;

    constructor(scene: Phaser.Scene, x?: number, y?: number, title?: string, message?: string | string[], style?: PixelUI.DialogStyle) {
        const maxWidth = GAME_WIDTH * 0.8;
        const maxHeight = GAME_HEIGHT * 0.8;

        // const textColor = Phaser.Display.Color.ValueToColor(PixelUI.theme.textColor());
        const headerColor = Phaser.Display.Color.ValueToColor(PixelUI.theme.textHeaderColor());
        const buttonColor = Phaser.Display.Color.ValueToColor(PixelUI.theme.styles.colorMain);

        const strokeColor = Phaser.Display.Color.ValueToColor(PixelUI.theme.textStrokeColor());
        const backdropColor = Phaser.Display.Color.ValueToColor(PixelUI.theme.backdropColor());

        const textSize = style.textSize || 'normal';
        const textAlign = style.textAlign || 'center';
        const strokeThickness = 6;

        const chest = new Phaser.GameObjects.Image(scene, 0, 0, 'chest-closed');
        const card = new Phaser.GameObjects.Image(scene, 0, 0, 'card-back');

        /* define label styles */
        const labelStyle: PixelUI.TextLabelStyle = {
            noShadow: true,
            align: textAlign,
            fixedWidth: maxWidth,
            wordWrap: { width: maxWidth - 20 },
            padding: { x: 10, y: 10 },
        };

        const headerStyle: PixelUI.TextLabelStyle = {
            ...labelStyle,
            textSize,
            color: headerColor.rgba,
            stroke: strokeColor.rgba,
            strokeThickness,
        };

        /* const messageStyle: PixelUI.TextLabelStyle = {
            ...labelStyle,
            textSize,
            color: textColor.rgba,
            stroke: strokeColor.rgba,
            strokeThickness,
        }; */

        const buttonStyle: PixelUI.ButtonStyle = {
            ...labelStyle,
            fillColor: buttonColor.rgba,
            textSize,
        };

        /* add title label */
        const titleLabel = TextLabelFactory(scene, 0, 0, title, headerStyle);
        let titleHeight = 0;
        if (title) {
            titleHeight = titleLabel.height;
        }

        /* add message label */
        /* const messageLabel = TextLabelFactory(scene, 0, 0, message, messageStyle);
        const messageHeight = messageLabel.height; */

        /* calc dialog height */
        const totalHeight = Math.min(titleHeight + 500, maxHeight);

        const dialogWidth = maxWidth;
        const dialogHeight = totalHeight;

        const px = -dialogWidth / 2;
        const py = -dialogHeight / 2;

        titleLabel.setOrigin(0.0, 0.0);
        titleLabel.setPosition(px, py);
        /* messageLabel.setOrigin(0.0, 0.0);
        messageLabel.setPosition(px, py + titleHeight); */

        /* add buttons */
        let buttons: PixelUI.Button[] = [];
        if (style.buttons) {
            const buttonCount = style.buttons.length;
            const buttonMargin = buttonCount === 1 ? maxWidth / 2 : 16;
            const buttonWidth = maxWidth / buttonCount - buttonMargin;

            buttons = style.buttons.map((button, index) => {
                return ButtonFactory(
                    scene,
                    px + (buttonWidth + buttonMargin) * (index + 0.5),
                    py + dialogHeight + 60,
                    button.text,
                    async (): Promise<void> => {
                        if (style.onSelect) {
                            style.onSelect(button.value);
                        }
                        await new Promise((resolve: any) => resolve());
                        // await this.close();
                    },
                    { ...buttonStyle, fixedWidth: buttonWidth }
                );
            });
        }

        /* generate backdrop */
        const backdrop = BackdropFactory(scene, {
            fillColor: backdropColor.rgba,
            onClick: () => {
                if (style.backdropClose && this.state === 'open') {
                    this.close();
                }
            },
        });

        const buttonHeight = buttons ? buttons[0].height : 0;
        const dialog = new ComponentBase(scene, 0, -buttonHeight / 2, [titleLabel, /*messageLabel, */ ...buttons], { fixedWidth: dialogWidth, fixedHeight: dialogHeight });

        super(scene, x, y, [backdrop, dialog, chest, card]);

        this.chest = chest;
        this.card = card;
        this.dialog = dialog;
        this.backdrop = backdrop;
        this.buttons = buttons;
        this.state = 'close';
        this.dialogWidth = dialogWidth;
        this.dialogHeight = dialogHeight;

        this.cardScale = 0.2;

        // this.dialog.setDepth(1002);
        // this.backdrop.setDepth(1002);
        // this.buttons.setDepth(1000);
        this.card.setVisible(false);
        this.card.setScale(this.cardScale);

        if (style.open) {
            this.open();
        } else {
            this.setVisible(false);
            this.setButtonActive(false);
        }

        // this.items = [];
        this.events = new Phaser.Events.EventEmitter();
    }

    public openChest(): void {
        this.scene.tweens.add({
            targets: this.chest,
            scaleX: 1.1,
            scaleY: 0.7,
            ease: 'Power1',
            duration: 500,
            onComplete: () => {
                this.chest.setTexture('chest-open');

                this.scene.tweens.add({
                    targets: this.chest,
                    scaleX: 1,
                    scaleY: 1,
                    ease: 'Power1',
                    duration: 150,
                    onComplete: () => {
                        this.card.setVisible(true);
                        this.scene.tweens.add({
                            targets: this.card,
                            scaleX: this.cardScale * 0.01,
                            ease: 'Linear',
                            duration: 150,
                            onComplete: () => {
                                this.card.setTexture('card-front');
                                this.scene.tweens.add({
                                    targets: this.card,
                                    scaleX: this.cardScale,
                                    scaleY: this.cardScale,
                                    ease: 'Linear',
                                    duration: 150,
                                    onComplete: () => {
                                        //
                                    },
                                });
                            },
                        });
                    },
                });
            },
        });
    }

    public async open(): Promise<void> {
        if (this.state === 'open') {
            logger.warn('[PixelUI] This dialog is already opened.');
            return;
        }

        this.setVisible(true);

        /* for (let index = 0; index < 3; index++) {
            const x = -70;
            const y = -160 + index * 190;
            const item = this.pick(enhancements);
            const enhancement = new LootboxItem(this.scene, x, y, this.dialogWidth, 180, item, item.ressource);
            this.items.push(enhancement);
            enhancement.events.on('click', (data: ILootboxData) => {
                this.items.forEach((enhancement: LootboxItem) => enhancement.destroy());
                this.events.emit('click', data);
                this.close();
            });
            this.add(enhancement);
        } */

        await this.dialog.open();
        this.setButtonActive(true);

        this.state = 'open';
    }

    /* public pick(arr: ILootboxData[]): ILootboxData {
        const weighted = [].concat(...arr.map((obj) => Array(Math.ceil(obj.weight * 100)).fill(obj)));
        return weighted[Math.floor(Math.random() * weighted.length)];
    } */

    public async close(): Promise<void> {
        if (this.state === 'close') {
            logger.warn('[PixelUI] This dialog is already closed.');
            return;
        }

        this.state = 'close';
        this.setButtonActive(false);

        this.backdrop.close();
        await this.dialog.close();

        this.setVisible(false);

        /* this.items.forEach((item) => item.destroy());
        this.items = []; */
    }

    private setButtonActive(active: boolean): void {
        if (this.buttons) {
            for (const button of this.buttons) {
                button.setActive(active);
            }
        }
    }
}

export function LootboxDialogFactory(scene: Phaser.Scene, style: PixelUI.DialogStyle): LootboxDialog {
    const centerX = scene.cameras.main.midPoint.x;
    const centerY = scene.cameras.main.midPoint.y;
    const dialog = new LootboxDialog(scene, centerX, centerY, ' ', '', style);
    scene.children.add(dialog);
    return dialog;
}
