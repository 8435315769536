import IBuffData from '../data/IBuffData';
import GameScene from '../scenes/GameScene';
import BuffManager from './BuffManager';
import CharacterBuffItem from './CharacterBuffItem';

export default class CharacterBuffs extends Phaser.GameObjects.Container {
    public scene: GameScene;

    public frameTime: number = 0;
    public buffManager: BuffManager;
    public items: CharacterBuffItem[] = [];

    constructor(scene: GameScene, buffManager: BuffManager) {
        super(scene, 110, 110, []);

        this.scene = scene;
        this.buffManager = buffManager;

        this.scene.add.existing(this);
        // eslint-disable-next-line @typescript-eslint/unbound-method
        this.scene.events.on('update', this.update, this);
    }

    update(time, delta): void {
        this.frameTime += delta;
        const every = 500;
        while (this.frameTime > every) {
            this.frameTime -= every;

            this.buffManager.items.forEach((data: IBuffData, index: number) => {
                if (!this.items.find((i) => i.itemData.type === data.type)) {
                    const buff = new CharacterBuffItem(this.scene, 60 * index, 0, 10, 10, data);
                    this.items.push(buff);
                    this.add(buff);
                }
            });
        }
    }
}
