import * as Phaser from 'phaser';
import BootScene from '@/scenes/BootScene';
import PreloadScene from '@/scenes/PreloadScene';
import TitleScene from '@/scenes/TitleScene';
import GameScene from './scenes/GameScene';
import HandlerScene from './scenes/HandlerScene';
import OutlinePipelinePlugin from 'phaser3-rex-plugins/plugins/outlinepipeline-plugin';
import ShakePositionPlugin from 'phaser3-rex-plugins/plugins/shakeposition-plugin';

export default class App extends Phaser.Game {
    public static MAX_SIZE_WIDTH_SCREEN = 1080;
    public static MAX_SIZE_HEIGHT_SCREEN = 1920;
    public static MIN_SIZE_WIDTH_SCREEN = 270;
    public static MIN_SIZE_HEIGHT_SCREEN = 480;
    public static SIZE_WIDTH_SCREEN = 540;
    public static SIZE_HEIGHT_SCREEN = 960;

    public static start(): App {
        const gameConfig: Phaser.Types.Core.GameConfig = {
            type: Phaser.AUTO,
            render: {
                pixelArt: false,
                antialias: true,
                antialiasGL: true,
            },
            /* width: gameWidth,
            height: gameHeight, */
            scale: {
                mode: Phaser.Scale.RESIZE,
                width: App.SIZE_WIDTH_SCREEN * 4,
                height: App.SIZE_HEIGHT_SCREEN * 4,
                min: {
                    width: App.MIN_SIZE_WIDTH_SCREEN * 4,
                    height: App.MIN_SIZE_HEIGHT_SCREEN * 4,
                },
                max: {
                    width: App.MAX_SIZE_WIDTH_SCREEN * 4,
                    height: App.MAX_SIZE_HEIGHT_SCREEN * 4,
                },
            },
            parent: '',
            plugins: {
                global: [
                    {
                        key: 'rexOutlinePipeline',
                        plugin: OutlinePipelinePlugin,
                        start: true,
                    },
                    {
                        key: 'rexShakePosition',
                        plugin: ShakePositionPlugin,
                        start: true,
                    },
                ],
            },
            // resolution: 1,
            fps: {
                target: 30,
                forceSetTimeOut: true,
            },
        };
        return new App(gameConfig);
    }

    constructor(config: Phaser.Types.Core.GameConfig) {
        super(config);
        this.scene.add('BootScene', BootScene);
        this.scene.add('HandlerScene', HandlerScene);
        this.scene.add('PreloadScene', PreloadScene);
        this.scene.add('TitleScene', TitleScene);
        this.scene.add('GameScene', GameScene);
        this.scene.start('BootScene');
    }
}
