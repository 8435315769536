import App from '@/app';
import { Scene } from 'phaser';

export default class HandlerScene extends Phaser.Scene {
    public gameScene: Scene;
    public sceneRunning: string;
    public sceneStopped: boolean = false;
    public parent: Phaser.Structs.Size;
    public sizer: Phaser.Structs.Size;

    /* constructor() {
        super('handler')
    } */

    create(): void {
        this.cameras.main.setBackgroundColor('#FFF');
        this.launchScene('preload', null);
        this.launchScene('hub', null);
    }

    launchScene(scene, data): void {
        this.scene.launch(scene, data);
        this.gameScene = this.scene.get(scene);
    }

    updateResize(scene): void {
        console.log('updateResize', scene);
        // eslint-disable-next-line @typescript-eslint/unbound-method
        scene.scale.on('resize', this.resize, scene);

        const scaleWidth = scene.scale.gameSize.width;
        const scaleHeight = scene.scale.gameSize.height;

        scene.parent = new Phaser.Structs.Size(scaleWidth, scaleHeight);
        scene.sizer = new Phaser.Structs.Size(scene.width, scene.height, Phaser.Structs.Size.FIT, scene.parent);

        scene.parent.setSize(scaleWidth, scaleHeight);
        scene.sizer.setSize(scaleWidth, scaleHeight);

        this.updateCamera(scene);
    }

    resize(gameSize): void {
        // 'this' means to the current scene that is running
        if (!this.sceneStopped) {
            const width = gameSize.width;
            const height = gameSize.height;

            this.parent.setSize(width, height);
            this.sizer.setSize(width, height);

            // updateCamera - TO DO: Improve the next code because it is duplicated
            const camera = this.cameras.main;
            const scaleX = this.sizer.width / App.SIZE_WIDTH_SCREEN;
            const scaleY = this.sizer.height / App.SIZE_HEIGHT_SCREEN;
            console.log('zoom1', scaleX, scaleY);
            if (camera) {
                camera.setZoom(Math.max(scaleX, scaleY));
                camera.centerOn(App.SIZE_WIDTH_SCREEN / 2, App.SIZE_HEIGHT_SCREEN / 2);
            }
        }
    }

    updateCamera(scene): void {
        const camera = scene.cameras.main;
        const scaleX = scene.sizer.width / App.SIZE_WIDTH_SCREEN;
        const scaleY = scene.sizer.height / App.SIZE_HEIGHT_SCREEN;
        console.log('zoom2', scaleX, scaleY, Math.max(scaleX, scaleY) / 3);
        camera.setZoom(Math.max(scaleX, scaleY));
        camera.centerOn(App.SIZE_WIDTH_SCREEN / 2, App.SIZE_HEIGHT_SCREEN / 2);
    }
}
