const enemies = {
    un01: {
        strength: 1.2,
        maxHealth: 300,
        skills: ['skill27'],
    },
    un02: {
        strength: 1.2,
        maxHealth: 300,
        skills: ['skill27'],
    },
    un03: {
        strength: 1.2,
        maxHealth: 300,
        skills: ['skill27', 'skill08'],
    },
    un10: {
        strength: 1.2,
        maxHealth: 300,
        skills: ['skill27', 'skill08'],
    },
    or01: {
        strength: 1.2,
        maxHealth: 300,
        skills: ['skill80'],
    },
    or02: {
        strength: 1.2,
        maxHealth: 300,
        skills: ['skill24'],
    },
    or03: {
        strength: 1.2,
        maxHealth: 300,
        skills: ['skill56'],
    },
    or04: {
        strength: 1.2,
        maxHealth: 300,
        skills: ['skill80'],
    },
    or05: {
        strength: 1.2,
        maxHealth: 300,
        skills: ['skill01'],
    },
    or06: {
        strength: 1.2,
        maxHealth: 300,
        skills: ['skill54', 'skill59'],
    },
    or07: {
        strength: 1.2,
        maxHealth: 300,
        skills: ['skill54', 'skill59'],
    },
    or08: {
        strength: 1.2,
        maxHealth: 300,
        skills: ['skill27'],
    },
    or09: {
        strength: 1.2,
        maxHealth: 300,
        skills: ['skill08', 'skill95'],
    },
    or10: {
        strength: 1.2,
        maxHealth: 300,
        skills: ['skill08', 'skill54', 'skill27'],
    },
};

export default enemies;
